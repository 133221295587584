<template>
  <div class="full-screen">
    <div class="alert alert-danger" role="alert" v-if="message">
      {{ message }}
    </div>
    <div class="left-order">
      <div class="tvhead rounded text-center" v-if="lang == 4">
        Preparing （ 准备中 ）
      </div>
      <div class="tvhead rounded text-center" v-if="lang == 3">
        准备中 （ Preparing ）
      </div>
      <div class="tvhead rounded text-center" v-if="lang == 2">准备中</div>
      <div class="tvhead rounded text-center" v-if="lang == 1">Preparing</div>
      <div class="tvbody p-3">
        <div class="tvorderpre" v-for="(o, idx) in prepare_list" :key="idx">
          {{ o }}
        </div>
      </div>
    </div>
    <div class="right-order">
      <div class="tvhead2 rounded text-center" v-if="lang == 4">
        Ready to Go ( 等待领取 )
      </div>
      <div class="tvhead2 rounded text-center" v-if="lang == 3">
        等待领取 ( Ready to Go )
      </div>
      <div class="tvhead2 rounded text-center" v-if="lang == 2">等待领取</div>
      <div class="tvhead2 rounded text-center" v-if="lang == 1">Ready to Go</div>
      <div class="tvbody2 p-3">
        <div class="tvorderready" v-for="(o, idx) in ready_list" :key="idx">
          {{ o }}
        </div>
      </div>
    </div>
    <div class="upbutton" @click="f11toggle">

    </div>
  </div>
</template>
<script>
import { getStore, setStore } from "../util/helper";

const axios = require("axios");
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
  components: {},
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      fullscreen: false,
      delay_timer: null,
      prepare_list: [],
      ready_list: [],
      year: new Date().getFullYear(),
      message: "",
      lang: 3,
      device_id: "",
      token: "",
    };
  },
  methods: {
    f11toggle() {
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelRequestFullScreen) {
          document.webkitCancelRequestFullScreen();
        } else if (document.mozCancelRequestFullScreen) {
          document.mozCancelRequestFullScreen();
        } else if (document.msExitRequestFullScreen) {
          document.msExitRequestFullScreen();
        }
        this.fullscreen = false;
      } else {
        let element = document.documentElement;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
          element.msRequestFullScreen();
        }
        this.fullscreen = true;
      }
    },
    get_order_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_orders();
      }, 15000);
    },
    async get_orders() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      this.message = "";

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("device_id", this.device_id);

      var that = this;
      axios({
        method: "post",
        url: "http://"+this.ipaddr+":8090/api/kds/customer",
        // url: "http://"+this.ipaddr+"/Api/test",
        data: bodyFormData,
      })
        .then(function (response) {
          // console.log("OK",response);
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.prepare_list = rt.prepare_list;
              that.ready_list = rt.ready_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              that.message = rt.message;
            }
          } else {
            that.message = "Access server error!!";
          }
          that.get_order_delay();
        })
        .catch(function (error) {
          // console.log("Access server Exception",error);
          that.message = "Access server Exception!!";
        });
    },
  },
  mounted() {
    this.device_id = getStore("device_id");
    this.token = new Date().getTime();
    if (!this.device_id) {
      this.device_id = this.token;
      setStore("device_id", this.device_id);
    }
    this.ipaddr = this.$route.query.ip;
    this.lang = this.$route.query.lang;
    if (this.lang != 1 && this.lang != 2 && this.lang != 4) {
      this.lang = 3;
    }
    this.windowHeight = window.innerHeight;
    this.get_orders();
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
  width: 100%;
}

.full-screen {
  height: 100%;
  width: 100%;
  font-size: 1.6vw;
  background: #505050;
}

.tvhead {
  width: 100%;
  height: 5vw;
  font-size: 2vw;
  font-weight: bold;
  background: #ffffff;
  color: #000000;
  border: 2px solid #5d832c;
  padding-top: 1vw;
}
.tvhead2 {
  width: 100%;
  height: 5vw;
  font-size: 2vw;
  font-weight: bold;
  background: #82e0aa;
  color: white;
  border: 2px solid #5d832c;
  padding-top: 1vw;
}
.tvbody {
  width: 100%;
  font-size: 2vw;
}
.tvorderpre {
  display: inline-block;
  padding: 0.5rem;
  margin: 0.25rem;
  color: black;
  background: rgb(126, 233, 112);
}
.tvorderready {
  display: inline-block;
  padding: 0.5rem;
  margin: 0.25rem;
  color: black;
  background: rgb(206, 119, 79);
}
.left-order {
  display: inline-block;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.6667%;
  max-width: 66.66667%;
  width: 66%;
  height: 100%;
  overflow: hidden;
}

.right-order {
  display: inline-block;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.33333%;
  max-width: 33.33333%;
  width: 33%;
  height: 100%;
  overflow: hidden;
}
.upbutton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 10vw;
  height: 10vh;
  background-color: #f00000;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";       /* IE 8 */
  filter: alpha(opacity=0);  /* IE 5-7 */
  -moz-opacity: 0;          /* Netscape */
  -khtml-opacity: 0;        /* Safari 1.x */
  opacity: 0;               /* Good browsers */
  z-index: 1000;
}
#footer-main {
  position: fixed;
  bottom: 0px;
  color: white;
  font-size: 0.6rem;
  height: 70px;
  text-align: center;
  width: 100%;
  background: #505050;
  div {
    width: 100%;
    text-align: right;
  }
}
</style>
