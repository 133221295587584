<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">{{ $t("Search") }}</h3>
            </b-card-header>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col cols="2" v-if="showLocation">
                    <base-input :label="$t('Store')">
                      <select
                        class="form-control"
                        v-model="cur_LocationID"
                        @change="get_user_data()"
                      >
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col cols="2">
                    <base-input :label="$t('Staff')">
                      <select
                        class="form-control"
                        v-model="cur_userIDn"
                      >
                        <option></option>
                        <option
                          v-for="user in userlist"
                          :key="user.IDn"
                          :value="user.IDn"
                        >
                          {{ user.UserName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col cols="2">
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="cur_start_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col cols="2">
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="cur_end_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col cols="2" class="mt-4 pl-5">
                    <b-button variant="primary" @click="form_search">{{ $t("Search") }}</b-button>
                  </b-col>
                  <b-col cols="2" class="mt-4">
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="getReportName('Tips', 'xlsx')">
                        <b-button variant="primary">XLSX</b-button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </b-col>
                </b-row>
                <b-row class="justify-content-lg-center">
                  <b-col class="text-center">
                  </b-col>
                  <b-col class="text-center">
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <p class="p-3" v-if="listdata">
            <b-card>
              <b-row>
                <b-col cols="12" class="font-weight-bold">
                  <b-row>
                    <b-col cols="3" v-if="showLocation">
                      {{ $t('Store') }}
                    </b-col>
                    <b-col cols="3">
                      {{ $t('Username') }}
                    </b-col>
                    <b-col cols="2" class="text-right">
                      {{ $t('Order Quantity') }}
                    </b-col>
                    <b-col cols="2" class="text-right">
                      {{ $t('Total') }}
                    </b-col>
                    <b-col cols="2" class="text-right">
                      {{ $t('Tips') }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-for="(data,idx) in listdata" :key="idx">
                <b-col cols="3" v-if="showLocation">
                  <div>{{ data.StoreName }}</div>
                </b-col>
                <b-col cols="3">
                  <div>{{ data.Username }}</div>
                </b-col>
                <b-col cols="2" class="text-right">
                  {{ parseInt(data.Count) }}
                </b-col>
                <b-col cols="2" class="text-right">
                  ${{ parseFloat(data.TotalAmount).toFixed(2) }}
                </b-col>
                <b-col cols="2" class="text-right">
                  ${{ parseFloat(data.TipsAmount).toFixed(2) }}
                </b-col>
              </b-row>
            </b-card>
          </p>
          <p class="p-3" v-else>
            {{ $t("No Records") }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-4"> <b-col> </b-col> </b-row
      ><!--  -->
    </b-container>
    <vue-element-loading
      :active="showLoading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    VueElementLoading,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
        maxDate: "today",
        enableTime: false,
        minuteIncrement: 60, // Set minute increment to 60 to allow only 0 minutes
        time_24hr: true, // Set time_24hr option to true for 24-hour format
      },
      showLoading: false,
      showLocation: true,

      token: "",
      user_id: 0,
      storelist: null,
      userlist: null,
      listdata: null,
      Count: 0,
      Amount: 0,

      cur_LocationID: "",
      cur_userIDn: 0,
      cur_start_dt: "",
      cur_end_dt: "",

      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
    };
  },
  computed: {
    is_english: function () {
      switch (this.$i18n.locale) {
        case 'en':
          return true;
        case 'cn':
          return false;
        case 'cn-t':
          return false;
        default:
          return true;

      }
    },
  },
  methods: {
    async get_store_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      const that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store/report_hourly",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.cur_LocationID = "";
              if (that.storelist.length == 1) {
                that.cur_LocationID = that.storelist[0].LocationID;
                that.showLocation = false;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_user_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_LocationID);

      const that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store/report_location_user",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.userlist = rt.data.userlist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    fillup_sheet() {
      // Init 2 dimensional array
      var dataarr = [];
      for (var i = 0; i < 10; i++) {
        dataarr[i] = [];
      }

      try {
        // Date line
        var y = 0;
        dataarr[y++][0] = this.cur_start_dt + " To " + this.cur_end_dt;
        if (this.cur_LocationID) {
          var showotherstr = "";
          for (var i = 0; i < this.storelist.length; i++) {
            if (this.cur_LocationID == this.storelist[i]["LocationID"]) {
              showotherstr += this.$t("Store") + ":" + this.storelist[i]["LocationName"] + ";  ";
            }
          }
          if (showotherstr) {
            dataarr[y++][0] = showotherstr;
          }
        }
        y++;

        // Data Loop
        if (!this.listdata) {
          dataarr[y++][0] = "No Data";
          return;
        }
        var x = 0;
        if (this.showLocation) {
          dataarr[y][x++] = this.$t('Store');
        }
        dataarr[y][x++] = this.$t('Username');
        dataarr[y][x++] = this.$t('Order Quantity');
        dataarr[y][x++] = this.$t('Total');
        dataarr[y][x++] = this.$t('Tips');
        y++;

        for (var key in this.listdata) {
          x = 0;
          if (this.showLocation) {
            dataarr[y][x++] = this.listdata[key].StoreName;
          }
          dataarr[y][x++] = this.listdata[key].Username;
          dataarr[y][x++] = parseInt(this.listdata[key].Count);
          dataarr[y][x++] = parseFloat(this.listdata[key].TotalAmount).toFixed(2);
          dataarr[y][x++] = parseFloat(this.listdata[key].TipsAmount).toFixed(2);
          y++; if (dataarr[y] == undefined) dataarr[y] = [];
        }
      } catch (err) {
        console.log("fillup_sheet err",err);
      }
      // console.log("fillup_sheet",dataarr);
      var sheetname = "Tips";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    remove_dashColons_replace_space_with_underscore(myString) {
      if (myString == undefined) {
        return '';
      }
      var newString = myString.replace(/[-:]/g, "");
      newString = newString.replace(" ", "_");
      return newString;
    },
    getReportName(reportName , reportType) {
      let start = this.remove_dashColons_replace_space_with_underscore(this.cur_start_dt);
      let end = this.remove_dashColons_replace_space_with_underscore(this.cur_end_dt);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    async get_list_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_userIDn) {
        bodyFormData.append("UserIDn", this.cur_userIDn);
      }
      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }

      var that = this;
      that.listdata = [];
      axios({
        method: "post",
        url: "/Api/Web/Report_tips",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.listdata = rt.data.datalist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            // console.log("Pos_sales_report_by_product ====>>> ", JSON.parse(JSON.stringify(that.listdata)));
            that.fillup_sheet();
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    form_cancel() {
      this.$router.push("/store");
    },
    async form_search() {
      this.get_list_data();
    },
    get_yesterday() {
      var today = new Date();
      today.setDate(today.getDate() - 1);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    async init_data() {
      this.cur_start_dt = this.get_today();
      this.cur_end_dt = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      await this.get_store_data();
      await this.get_user_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
