<template>
  <div>
    <b-container fluid class="content inoutreport">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4" v-if="storelist && (storelist.length > 1)">
                  <el-select v-model="cur_LocationID" size="mini" :placeholder="$t('Select Store')">
                    <el-option
                      key="nonestore"
                      label=""
                      value="">
                    </el-option>
                    <el-option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :label="dt.LocationID + ' - ' + dt.LocationName"
                      :value="dt.LocationID">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="cur_UserName" size="mini" :placeholder="$t('Employee')">
                    <el-option
                      key="noneuser"
                      label=""
                      value="">
                    </el-option>
                    <el-option
                      v-for="(dt, idx) in employeelist"
                      :key="idx"
                      :label="dt"
                      :value="dt">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-date-picker
                    v-model="cur_start_dt"
                    type="date"
                    size="mini"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    editablet=true
                    :placeholder="$t('Start Date')">
                  </el-date-picker>
                </el-col>
                <el-col :span="4">
                  <el-date-picker
                    v-model="cur_end_dt"
                    type="date"
                    size="mini"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    editablet=true
                    :placeholder="$t('End Date')">
                  </el-date-picker>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="cur_AdjustMinutes" size="mini" :placeholder="$t('Adjust Minutes')">
                    <el-option :label="$t('Adjust Minutes')" value=""></el-option>
                    <el-option label="5 Minutes" value="5"></el-option>
                    <el-option label="10 Minutes" value="10"></el-option>
                    <el-option label="15 Minutes" value="15"></el-option>
                    <el-option label="30 Minutes" value="30"></el-option>
                    <el-option label="60 Minutes" value="60"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="small"
                    @click="get_data_list(0)"
                    >{{ $t("Search") }}</el-button
                  >
                </el-col>
                <el-col :span="4">
                  <section>
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="getReportName('InOut', 'xlsx')">
                        <el-button
                          type="primary"
                          icon="el-icon-document"
                          size="small"
                          >{{ $t("Export") }}</el-button
                        >
                      </xlsx-download>
                    </xlsx-workbook>
                  </section>
                </el-col>
              </el-row>
            </b-form>
          </b-card>
          <b-card>
            <div class="p-0" v-if="datalist">
              <div class="text-center">{{ daterange }}</div>
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="datalist"
                show-summary
                size="mini"
                fit
                :summary-method="get_summaries"
              >
                <el-table-column label="Name" prop="UserName"></el-table-column>
                <el-table-column label="Date" prop="Date"></el-table-column>
                <el-table-column label="In" prop="ClockIn"></el-table-column>
                <el-table-column label="Out" prop="ClockOut"></el-table-column>
                <el-table-column label="Adj.In" prop="AdjustIn"></el-table-column>
                <el-table-column label="Adj.Out" prop="AdjustOut"></el-table-column>
                <el-table-column label="Hours" prop="Hours"></el-table-column>
                <el-table-column label="Adj.H." prop="AdjHour">
                  <template v-slot="{ row }">
                    <div v-if="row.Ihour" @click="adjhour_changed(row)">
                      {{ row.AdjHour }}
                    </div>
                    <div v-else>
                      <el-input
                        v-model="row.AdjHour"
                        size="mini"
                        :ref="'inout_hour' + row.IDn"
                        @change.native="adjhour_save_change(row)"
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Rate" prop="HourlyRate"></el-table-column>
                <el-table-column label="Salary" prop="Salary"></el-table-column>
                <el-table-column label="Note" prop="Remark">
                  <template v-slot="{ row }">
                    <div
                      v-if="row.Iremark"
                      @click="remark_changed(row, false)"
                      style="width: 100%; height: 1rem"
                    >
                      {{ row.Remark }}
                    </div>
                    <div v-else>
                      <el-input
                        v-model="row.Remark"
                        size="mini"
                        :ref="'inout_remark' + row.IDn"
                        @change.native="remark_changed(row, true)"
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <!-- b-pagination
                v-model="current_page"
                :total-rows="inout_total"
                :per-page="per_page"
              ></b-pagination -->
            </div>
            <div class="p-3" v-else>
              {{ $t("No Records") }}
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <vue-element-loading
      :active="showLoading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Select,
  Option,
  DatePicker,
  TimeSelect,
} from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Pagination.name]: Pagination,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    VueElementLoading,
    // InputAutocomplete,
  },
  data() {
    return {
      cur_LocationID: "",
      cur_UserName: "",
      cur_start_dt: "",
      cur_end_dt: "",
      cur_start_tm: "00:00",
      cur_end_tm: "00:00",
      cur_AdjustMinutes: "",

      todaystr: "",
      yesterdaystr: "",
      daterange: "",

      storelist: null,
      employeelist: null,
      inoutlist: null,
      datalist: null,
      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
      // sheets: [{ name: "Sheet1", data: [{ a: 1, b: 2, c: 2 },{ a: 1, b: 2, c: 2, d: 2 },{},{ a: 1, b: 2, c: 2, d: 2 }] }],

      picker_config: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },

      delay_timer: null,

      current_page: 1,
      inout_total: 0,
      per_page: 20,

      token: "",
      user_id: 0,
      showLoading: false,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_data_list(0);
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    get_summaries(param) {
      const { columns, data } = param;
      const len = columns.length;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total";
        } else if (index === 6 || index === 7 || index === 9) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    getReportName(reportName , reportType) {
      let report_start_date = this.daterange.slice(0, 10);
      let report_end_date = this.daterange.slice(-10);
      let start = this.remove_dash(report_start_date);
      let end = this.remove_dash(report_end_date);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    adjhour_changed(row) {
      row.Ihour = false;
      let idx = "inout_hour" + row.IDn;
      this.$nextTick(() => {
        this.$refs[idx].focus();
      });
    },
    remark_changed(row, status) {
      row.Iremark = status;
      let idx = "inout_remark" + row.IDn;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Notes", row.Remark);
      bodyFormData.append("Changed", 1);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inout/update",
        data: bodyFormData,
      })
      .then(function (response) {
        that.showLoading = false;
        if (response.status == "200") {
          // Do nothing
        } else {
          // that.$router.push("/login");
        }
      })
      .catch(function (error) {
        that.showLoading = false;
        // console.log(error);
        that.$router.push("/login");
      });

      this.$nextTick(() => {
        if (row.Iremark) {
          this.fillup_sheet();
        } else {
          this.$refs[idx].focus();
        }
      });
    },
    get_adjust_minute(dttm) {
      var tm = dttm.substr(11, 8);
      var adj = 0; // this.cur_AdjustMinutes;
      var hour = parseInt(tm.substr(0, 2));
      var mintue = parseInt(tm.substr(3, 2));
      var second = parseInt(tm.substr(6, 2));

      if (this.cur_AdjustMinutes <= 0) {
        return tm.substr(0, 5);
      }

      mintue += second / 60 + this.cur_AdjustMinutes / 2;
      while (adj < 60) {
        adj += parseFloat(this.cur_AdjustMinutes);
        if (mintue < adj) {
          adj -= parseFloat(this.cur_AdjustMinutes);
          return ((hour + "").padStart(2, "0") + ":" + (adj + "").padStart(2, "0"));
        }
      }
      hour++;
      return (hour + "").padStart(2, "0") + ":00";
    },
    fillup_sheet() {
      if (this.datalist.length <= 0) {
        return;
      }

      var sheetsobj = {};
      for (var i = 0; i < this.datalist.length; i++) {
        if (!sheetsobj.hasOwnProperty(this.datalist[i].UserName)) {
          sheetsobj[this.datalist[i].UserName] = [];
        }
        sheetsobj[this.datalist[i].UserName].push(this.datalist[i]);
      }

      var dataarr = [];
      var thh, tadjhh, salary;
      for (const UserName in sheetsobj) {
        // User name
        thh = 0;
        tadjhh = 0;
        salary = 0;
        for (var i = 0; i < sheetsobj[UserName].length; i++) {
          if (i == 0) {
            dataarr.push([
              sheetsobj[UserName][i]["UserName"],
              "",
              "",
              this.daterange,
            ]);
            dataarr.push([
              "Date",
              "Clock In",
              "Clock Out",
              "Adjust In",
              "Adjust Out",
              "Hours",
              "Adjust Hours",
              "Rate",
              "Salary",
              "Remark",
            ]);
          }
          dataarr.push([
            sheetsobj[UserName][i]["Date"],
            sheetsobj[UserName][i]["ClockIn"],
            sheetsobj[UserName][i]["ClockOut"],
            sheetsobj[UserName][i]["AdjustIn"],
            sheetsobj[UserName][i]["AdjustOut"],
            sheetsobj[UserName][i]["Hours"],
            sheetsobj[UserName][i]["AdjHour"],
            sheetsobj[UserName][i]["HourlyRate"],
            sheetsobj[UserName][i]["Salary"],
            sheetsobj[UserName][i]["Remark"],
          ]);
          thh += parseFloat(sheetsobj[UserName][i]["Hours"]);
          tadjhh += parseFloat(sheetsobj[UserName][i]["AdjHour"]);
          salary += parseFloat(sheetsobj[UserName][i]["Salary"]);
        }
        dataarr.push([
          "Total",
          "",
          "",
          "",
          "",
          thh.toFixed(2),
          tadjhh.toFixed(2),
          "",
          salary.toFixed(2),
          "",
        ]);
        dataarr.push([]);
      }
      var sheetname = "AttendanceReport";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    adjhour_save_change(row) {
      row.Ihour = true;
      this.calculate_recodes(false);

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("AdjHours", row.AdjHour);
      bodyFormData.append("Changed", 1);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inout/update",
        data: bodyFormData,
      })
      .then(function (response) {
        that.showLoading = false;
        if (response.status == "200") {
          // Do nothing
        } else {
          // that.$router.push("/login");
        }
      })
      .catch(function (error) {
        that.showLoading = false;
        // console.log(error);
        that.$router.push("/login");
      });
    },
    calculate_recodes(cal) {
      this.inout_total = 0;
      var sh, sm, ss, eh, em, es;
      for (var i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].ClockIn && this.datalist[i].ClockOut) {
          var tin = new Date(this.datalist[i].Date + " " + this.datalist[i].AdjustIn);
          var tout = new Date(this.datalist[i].DateOut + " " + this.datalist[i].AdjustOut);
          var diff = tout.getTime() - tin.getTime();
          diff /= 1000;
          var hdiff = (diff / 3600).toFixed(2);   
          // var sdiff = ((diff - (hdiff * 3600)) / 60).toFixed(0);
          this.datalist[i].Hours = hdiff;
        } else {
          this.datalist[i].Hours = 0;
        }
        if (cal && !this.datalist[i].Changed) {
          this.datalist[i].AdjHour = this.datalist[i].Hours;
        }
        this.datalist[i].Salary = (
          parseFloat(this.datalist[i].AdjHour) *
          parseFloat(this.datalist[i].HourlyRate)
        ).toFixed(2);
      }
      this.fillup_sheet();
    },
    make_recordlist() {
      this.datalist = [];
      this.inout_total = 0;
      for (var i = 0; i < this.inoutlist.length; i++) {
        // [IDn] => 2
        // [Type] => 3
        // [UserName] => AAAA
        // [LocationID] => DVPJ
        // [LocationName] => Jack Test store
        // [UserName] => Owner
        // [FirstName] => 
        // [LastName] => 
        // [ClockDateTime] => 2021-05-10 08:04:12.000
        // [Employee] => Owner
        // [Date] => 2021-05-09
        // [DateOut] => 2021-05-10
        // [ClockIn] => 00:05:00
        // [ClockOut] => 08:04:12
        // [AdjustIn] => 
        // [AdjustOut] => 
        // [Hours] => 0
        // [AdjHour] => 0
        // [HourlyRate] => 
        // [Salary] => 0
        // [Remark] => 
        // [Ihour] => 0
        // [Iremark] => 0
        this.datalist.push({
          IDn: this.inoutlist[i].IDn,
          UserName: this.inoutlist[i].UserName,
          Date: (this.inoutlist[i].ClockInDateTime).substr(0, 10),
          ClockIn: (this.inoutlist[i].Type==2)?"":(this.inoutlist[i].ClockInDateTime).substr(11,5),
          DateOut: (this.inoutlist[i].Type==1)?"":(this.inoutlist[i].ClockOutDateTime).substr(0, 10),
          ClockOut: (this.inoutlist[i].Type==1)?"":(this.inoutlist[i].ClockOutDateTime).substr(11,5),
          AdjustIn: this.get_adjust_minute(this.inoutlist[i].ClockInDateTime),
          AdjustOut: this.get_adjust_minute(this.inoutlist[i].ClockOutDateTime),
          Hours: 0,
          AdjHour: this.inoutlist[i].AdjHours,
          HourlyRate: this.inoutlist[i].HourlyRate,
          Salary: 0,
          Remark: this.inoutlist[i].Notes,
          Changed: this.inoutlist[i].Changed,
          Ihour: 0,
          Iremark: "",
        });
      }
      this.calculate_recodes(true);
    },
    get_data_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_data_list(0);
      }, 400);
    },
    async get_data_list(init) {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      this.showLoading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_UserName) {
        bodyFormData.append("UserName", this.cur_UserName);
      }
      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      } else {
        bodyFormData.append("start_dt", this.yesterdaystr);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      } else {
        bodyFormData.append("end_dt", this.todaystr);
      }
      // if (this.cur_start_tm) {
      //   bodyFormData.append("start_tm", this.cur_start_tm);
      // }
      // if (this.cur_end_tm) {
      //   bodyFormData.append("end_tm", this.cur_end_tm);
      // }
      if (this.cur_AdjustMinutes) {
        bodyFormData.append("AdjustMinutes", this.cur_AdjustMinutes);
      }

      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inout",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.employeelist = rt.data.employees;
              that.storelist = rt.data.stores;
              that.inoutlist = rt.data.inouts;
              that.daterange = rt.data.daterange;
              that.per_page = rt.data.per_page;
              that.make_recordlist();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      var day = new Date();
      this.todaystr = day.getFullYear() + "-" + String(day.getMonth() + 1).padStart(2, "0") + "-" + String(day.getDate()).padStart(2, "0");
      day.setDate(day.getDate() - 1);
      this.yesterdaystr = day.getFullYear() + "-" + String(day.getMonth() + 1).padStart(2, "0") + "-" + String(day.getDate()).padStart(2, "0");

      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }

      return this.get_data_list(1);
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.inoutreport .search-button {
  margin-top: 2rem;
}
.inoutreport .select_text {
  margin-left: -1rem;
}
.inoutreport .card .table th {
  padding: 0rem;
}
.inoutreport .card .table td {
  padding: 0rem;
}
.inoutreport .picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.inoutreport .el-collapse-item__header {
  background-color: #f0f0f0;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.inoutreport .el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.inoutreport #mymodalclass .modal-title {
  font-size: 2rem !important;
}
.inoutreport #mymodalclass .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.inoutreport .form-group {
  margin-bottom: 0.5rem;
}
</style>
