<template>
  <div>
    <b-container fluid class="content">
      <b-card no-body>
        <b-card-body>
          <b-form>
            <b-row style="height: 80px">
              <b-col cols="3">
                <base-input :label="$t('Vendor')">
                  <el-autocomplete
                    class="inline-input el-dropdown"
                    v-model="cur_vendorName"
                    value-key="CompanyName"
                    :fetch-suggestions="querySearch"
                    :placeholder="$t('All Vendors')"
                    @select="selectVendor"
                    @blur="selectVendor"
                  >
                    <!-- <i
                      class="el-icon-edit el-input__icon"
                      slot="suffix"> 
                    </i> -->
                    <template slot-scope="{ item }">
                      <span>{{ item.CompanyName }}</span>
                    </template>
                  </el-autocomplete>
                </base-input>
              </b-col>
              <b-col cols="3">
                <base-input :label="$t('Store')">
                
                  <el-select
                    class="el-dropdown"
                    v-model="cur_store_ID"
                    :placeholder="$t('All Stores')"
                  >
                    <el-option
                      v-for="store in storelist_dropdown"
                      :key="store.IDn"
                      :label="store.StoreName"
                      :value="store.IDn"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col cols="2">
                <base-input :label="$t('From Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs_from"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="from_date"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-col cols="2">
                <base-input :label="$t('To Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs_to"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="to_date"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-row class="col-2">
                <b-col cols="5">
                  <button class="btn btn-primary" @click.prevent="form_search" style="margin-top: 30px; margin-left: -10px">
                    {{ $t("Search") }}
                  </button>
                </b-col>
                <b-col cols="7">
                  <!-- <a v-bind:href="pdfBaseUrl + '/Web/Report/purchase_summary?' + 
                    'token=' + token + 
                    '&user_id=' + user_id +
                    '&FromDate=' + this.from_date +
                    '&ToDate=' + this.to_date +
                    '&StoreID=' + this.cur_store_ID +
                    '&VendorIDn=' + this.cur_vendorIDn +
                    '&PONumber=' + this.cur_order_no
                    " class="btn btn-primary" target="_blank" style="margin-bottom: 5px">PDF</a> -->
                  <xlsx-workbook>
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in sheets"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                    />
                    <xlsx-download :filename="getReportName('PurchaseItem', 'xlsx')">
                      <button class="btn btn-primary">XLSX</button>
                    </xlsx-download>
                  </xlsx-workbook>
                </b-col>
              </b-row>
            </b-row>
            <hr class="hr-text" :data-content="$t('OR')" >
            <b-row style="height: 80px; margin-top: -15px">
              <b-col cols="3">
                <base-input :label="$t('PO')">
                  <b-form-input v-model="cur_order_no" class="b-input" :placeholder="$t('PO')"></b-form-input>
                </base-input>
              </b-col>
              <b-col cols="3">
                <button class="btn btn-primary" @click.prevent="form_search_by_PO" style="margin-top: 30px">
                    {{ $t("Search") }}
                </button>
              </b-col>
              
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-container>
    <b-container fluid>
      <b-card>
        <b-card-body :style="{ overflowX: 'auto' }">
          <div v-bind:style="{ width: report_width }">
            <b-row v-if="!isSearchingByPO">
              <b-col cols="3">
                {{ $t('Vendor') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_vendorName_searched }}</span>
              </b-col>
              <b-col cols="2">
                {{ $t('Store') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_store_ID_searched }}</span> 
              </b-col>
              <b-col cols="3">
                &emsp; 
              </b-col>
              <b-col cols="4">
                <div class="float-right">
                  {{ $t('Time') + ': ' }} &emsp; <span style="font-weight: bold;">{{ from_date_searched }}</span>&emsp;
                  {{ " " + $t('To') + " "}} &emsp; <span style="font-weight: bold;">{{ to_date_searched }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <span style="font-weight: bold;">{{ cur_order_no_searched }}</span>
            </b-row>
          </div>
          <div v-bind:style="{ width: report_width }">
            <!-- Table Headers -->
            <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
              <b-col cols="1">
                <div style="text-align: center; fontWeight: bold; fontSize: 17px;">{{ "PO#" }}</div>
              </b-col>
              <b-row class="col-3">
                <b-col cols="6">
                  <div style="text-align: center; fontWeight: bold; fontSize: 17px;">{{ $t('Order Date') }}</div>
                </b-col>
                <b-col cols="6">
                  <div style="text-align: left; fontWeight: bold; fontSize: 17px;">{{ $t('Complete Date') }}</div>
                </b-col>
              </b-row>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Subtotal') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Tax') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Shipping2') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Other Fee') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Total') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Payment') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Balance') }}</div>
              </b-col>
              <b-col cols="1">
                <div v-bind:style="headerStyleObject">{{ $t('Order By') }}</div>
              </b-col>
            </b-row>
            <!-- Table Contents -->
            <template v-for="(vendor, vendor_key) in list_data" >
              <b-row v-bind:key="vendor_key" v-bind:style="{ marginTop: '5px' }"  v-if="vendor['VendorName']">
                <b-col  class="col-12">
                  <div v-bind:style="titleStyleObject">
                    {{ $t('Vendor') + ': ' + vendor['VendorName'] }}
                  </div>
                </b-col>
                <b-col  class="col-12">
                  <template v-for="(list, list_key) in vendor['data']">
                    <b-row v-bind:key="list_key" v-bind:style="{ marginTop: '5px' }"  v-if="vendor['VendorName']">
                      <b-col cols="1">
                        <div style="text-align: center; fontSize: 14px;">{{ list['OrderID'] }}</div>
                      </b-col>
                      <b-row class="col-3">
                        <b-col cols="6">
                          <div style="text-align: center; fontSize: 14px;">{{ list['OrderDate'].substr(0,10) }}</div>
                        </b-col>
                        <b-col cols="6">
                          <div style="text-align: left; fontSize: 14px;">{{ list['EndDate'].substr(0,10) }}</div>
                        </b-col>
                      </b-row>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['SubTotal'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['TotalTax'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['ShipFee'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['OtherFees'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['TotalAmount'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['PaidAmount'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ '$ ' + list['Balance'] }}</div>
                      </b-col>
                      <b-col cols="1">
                        <div v-bind:style="cellStyleObject">{{ list['CreateByName'] }}</div>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col class="col-12">
                  <hr style="margin: 0px">
                  <b-row v-bind:style="{ marginTop: '5px' }"  v-if="vendor['VendorName']">
                    <b-row class="col-3">
                      <b-col cols="6">
                        <div v-bind:style="headerStyleObject" style="text-align: right">{{ ' ' }}</div>
                      </b-col>
                      <b-col cols="6">
                        <div v-bind:style="headerStyleObject" style="text-align: center">{{ ' ' }}</div>
                      </b-col>
                    </b-row>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ $t('Total') }}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorSubtotal']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorTax']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorShippingFee']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorOtherFees']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorTotal']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorPayment']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ '$' + vendor['VendorBalance']}}</div>
                    </b-col>
                    <b-col cols="1">
                      <div v-bind:style="headerStyleObject">{{ ' ' }}</div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
} from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [flatPicker.name]: flatPicker,
    VueElementLoading,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      configs_from: {
        allowInput: false,
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        maxDate: "today",
        time_24hr: true,
      },
      configs_to: {
        allowInput: false,
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        maxDate: "today",
        time_24hr: true,
      },

      markStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "20px",
      },
      headerStyleObject: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "17px",
      },
      cellStyleObject: {
        textAlign: "right",
        fontSize: "14px",
      },
      titleStyleObject: {
        textAlign: "left",
        // textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "22px",
      },
      nameStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
      },

      token: "",
      user_id: 0,
      onlyself: null,

      storelist: null,
      storelist_dropdown: null,
      computerlist: null,
      vendorlist: null,
      vendorlist_dropdown: null,

      list_data: null,
      data_column_count: null,

      from_date: null,
      to_date: null,
      from_date_searched: null,
      to_date_searched: null,

      cur_store_ID: null,
      cur_computer: null,
      cur_vendorIDn: null,
      cur_vendorName: null,
      cur_order_no: null,
      cur_vendorName_searched: null,
      cur_store_ID_searched: null,
      cur_order_no_searched: null,
      showloading: false,

      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],

      url: "",

      report_width: '100%',
      ac_time: true,
      summary_only: true,
      isSearchingByPO: false,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_list_data();
    },
    storelist: function (newval, oldval) {
      this.set_storelist_dropdown();
    },
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    querySearch(queryString, cb) {
      var links = this.vendorlist_dropdown;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.CompanyName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    getNameByVendorIDn(idn){
      for (const [key, value] of Object.entries(this.vendorlist)) {
        if(value.IDn == idn){
          return value.CompanyName;
        }
      }
      return "-";
    },
    async get_list_data() {
      // this will be called only after form is valid. You can do api call here to login
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if(this.cur_order_no != null){
        bodyFormData.append("PONumber", this.cur_order_no);
      } else {
        bodyFormData.append("FromDate", this.from_date);
        bodyFormData.append("ToDate", this.to_date);
        if (this.cur_store_ID != null) {
          bodyFormData.append("StoreID", this.cur_store_ID);
        }
        if (this.cur_vendorIDn != null) {
          bodyFormData.append("VendorIDn", this.cur_vendorIDn);
        }
      }
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase_summary",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.onlyself = rt.data.onlyself;
              that.storelist = rt.data.storelist;
              that.list_data = rt.data.list_data;
              that.checkPOStatus(JSON.parse(JSON.stringify(that.list_data)));
              that.get_vendors();
              if(!that.cur_vendorName){
                that.cur_vendorName_searched = 'All Vendors';
              } else {
                that.cur_vendorName_searched = that.cur_vendorName;
              }
              if(!that.cur_store_ID){
                that.cur_store_ID_searched = 'All Stores';
              } else {
                that.cur_store_ID_searched = that.cur_store_ID;
              }
              if(that.cur_order_no){
                that.cur_order_no_searched = that.cur_order_no;
              }
              that.from_date_searched = that.from_date;
              that.to_date_searched = that.to_date;
              // that.report_width =
              //   String((rt.data.data_column_count + 1) * 240) + "px";
              if (that.list_data) {
                // that.fillup_sheet();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_vendors() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase_summary/getVendors",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vendorlist = rt.data.vendorlist;
              that.set_vendorlist_dropdown();
              that.setVendorName();
              that.formatListData(JSON.parse(JSON.stringify(that.list_data)));
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.cur_order_no = null;
      this.isSearchingByPO = false;
      this.get_list_data();
    },
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    getReportName(reportName , reportType) {
      let start = this.remove_dash(this.from_date_searched);
      let end = this.remove_dash(this.to_date_searched);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    async form_search_by_PO() {
      this.from_date = this.setStartandEndDate('from');
      this.to_date = this.setStartandEndDate('to');
      this.cur_vendorName = null;
      this.cur_vendorIDn = null;
      this.cur_store_ID = null;
      this.isSearchingByPO = true;
      // TODO: Check PO input
      this.get_list_data();
    },
    async init_data() {
      this.from_date = this.setStartandEndDate('from');
      this.to_date = this.setStartandEndDate('to');
      this.token = getStore("token");
      this.user_id = getStore("user_id");

      this.get_list_data();
      
    },
    selectVendor(evt){
      if(evt.type == 'blur'){
        this.cur_vendorName = null;
        this.cur_vendorIDn = null;
      } else {
        this.cur_vendorName = evt.CompanyName;
        this.cur_vendorIDn = evt.IDn;
      }
    },
    set_vendorlist_dropdown() {
      this.vendorlist_dropdown = [];
      this.vendorlist_dropdown.push({ IDn: "", CompanyName: "" });
      for (var key in this.vendorlist) {
        let vendor = {
          IDn: this.vendorlist[key].IDn,
          CompanyName: this.vendorlist[key].CompanyName,
        };
        this.vendorlist_dropdown.push(vendor);
      }
    },
    set_storelist_dropdown() {
      this.storelist_dropdown = [];
      this.storelist_dropdown.push({ IDn: "", StoreName: "" });
      for (var key in this.storelist) {
        let store = {
          IDn: this.storelist[key].LocationID,
          StoreName: this.storelist[key].LocationName,
        };
        this.storelist_dropdown.push(store);
      }
    },
    setVendorName(){
      if(this.list_data){
        for(var i=0; i<this.list_data.length; i++){
          this.list_data[i].VendorName = this.getNameByVendorIDn(this.list_data[i].VendorIDn);
        }
      }
      this.$forceUpdate();
    },
    setStartandEndDate(target) {
      // Get current time
      var today = new Date();
      // var month = 5;
      // today.setFullYear(2020, month-1, 31);
      // Get Current day, month, year
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy = today.getFullYear();
      // Get 1 month ago day, month, year
      var dd_1_Month_Ago = dd;
      var mm_1_Month_Ago;
      var yyyy_1_Month_Ago = yyyy;
      // Case 1: January now!
      if(mm === "01"){
        mm_1_Month_Ago = "12";  // Set month to be Dec
        yyyy_1_Month_Ago = parseFloat(yyyy) - 1; // Set year to be last year
      }
      // Case 2: March now!
      else if(mm === "03"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is March 29/30/31, 1 month ago is Feb 28/29, depends on if leap year
        if(parseFloat(dd) > 28){
          if(((0 == parseFloat(yyyy) % 4) && (0 != parseFloat(yyyy) % 100) || (0 == parseFloat(yyyy) % 400))){
            dd_1_Month_Ago = "29";
          } else {
            dd_1_Month_Ago = "28";
          }
        }
      }
      // Case 3: Feb, Apr, June, Sept or Nov now!
      else if(mm === "02" || mm === "04" || mm === "06" || mm === "09" || mm === "11"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month
      }
      // Case 4: May, July, Aug, Oct or Dec now!
      else {
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is May 31, 1 month ago is Apr 30
        if(parseFloat(dd) > 30){
          dd_1_Month_Ago = "30";
        }
      }

      // Return
      if(target == 'from'){
        return yyyy_1_Month_Ago + '-' + mm_1_Month_Ago + '-' + dd_1_Month_Ago;
      } else if(target == 'to'){
        return yyyy + '-' + mm + '-' + dd;
      }
    },
    checkPOStatus(data){
      if(data){
        for(var i=0; i<data.length; i++){
          if(data[i].Status !== 'Complete'){
            this.list_data = null;
            alert(data[i].OrderID + " is not completed yet. Purchase Summary only displays completed PO.");
            break;
          }
        }
      }
    },
    formatListData(data){
      if(!data) return;
      const before = JSON.parse(JSON.stringify((data)));
      let after = {};

      for (var i = 0; i < before.length; i++) {
        var obj = before[i];
        if (!after.hasOwnProperty(obj.VendorIDn)) {
          after[obj.VendorIDn] = {
            data: [],
            VendorIDn: obj.VendorIDn,
            VendorName: obj.VendorName,
            VendorSubtotal: 0,
            VendorTax: 0,
            VendorShippingFee: 0,
            VendorOtherFees: 0,
            VendorTotal: 0,
            VendorPayment: 0,
            VendorBalance: 0,
          };
        }
        after[obj.VendorIDn].data.push(obj);
        after[obj.VendorIDn].VendorSubtotal += Number(obj.SubTotal);
        after[obj.VendorIDn].VendorTax += Number(obj.TotalTax);
        after[obj.VendorIDn].VendorShippingFee += Number(obj.ShipFee);
        after[obj.VendorIDn].VendorOtherFees += Number(obj.OtherFees);
        after[obj.VendorIDn].VendorTotal += Number(obj.TotalAmount);
        after[obj.VendorIDn].VendorPayment += Number(obj.PaidAmount);
        after[obj.VendorIDn].VendorBalance += Number(obj.Balance);
      }
      for (const [vendorIDn, vendor] of Object.entries(after)) {
        for (const [vendor_details_key, vendor_details] of Object.entries(vendor)) {
          if(vendor_details_key == 'data'){
            for (var i=0; i<vendor_details.length; i++) {
              for (const [key, value] of Object.entries(vendor_details[i])) {
                if(!isNaN(Number(value))){
                  after[vendorIDn][vendor_details_key][i][key] = Number(value).toFixed(2);
                }
              }
            }
          } else {
            if(!isNaN(Number(vendor_details))){
              after[vendorIDn][vendor_details_key] = Number(vendor_details).toFixed(2);
            }
          }
        }
        // sort by orderDate
        vendor.data.sort(this.compareOrderDate);
        // sort by PO Number
        vendor.data.sort(this.comparePONumber);
      }

      this.list_data = after;
    },
    compareOrderDate(a, b) {
      if (a.OrderDate.substr(0, 10) > b.OrderDate.substr(0, 10)) {
        return -1;
      }
      if (a.OrderDate.substr(0, 10) < b.OrderDate.substr(0, 10)) {
        return 1;
      }
      return 0;
    },
    comparePONumber(a, b){
      if(a.OrderDate.substr(0, 10) === b.OrderDate.substr(0, 10)){
        if (a.OrderID > b.OrderID) {
          return -1;
        }
        if (a.OrderID < b.OrderID) {
          return 1;
        }
      }
      return 0;
    },
    form_cancel() {
      this.$router.push("/store");
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown").length; i++){
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown")[i].style.width = document.getElementsByClassName("b-input")[0].parentElement.clientWidth + "px";
        //document.getElementsByClassName("el-dropdown")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.height = "43px";
      }
    },
    
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
    setTimeout(()=>{
      this.calculate_el_select_width()
    },50); // timer is used to avoid funciton call before left menu is loaded
    window.addEventListener('resize', this.calculate_el_select_width);
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
</style>
<style lang="scss" scoped>
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  margin: 0em;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}
</style>
