<template>
  <footer class="footer px-4">
    <b-row align-v="center" class="justify-content-lg-between"> </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
