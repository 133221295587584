<template>
  <div>
    <b-container fluid class="content">
      <button v-if="user_name == 'PKAdmin'" class="btn btn-primary" @click.prevent="init_records">{{ "Init Data" }}</button>
      <button v-if="user_name == 'PKAdmin'" class="btn btn-primary" @click.prevent="daily_routine">{{ "Run Daily Routine" }}</button>
      <b-card no-body>
        <b-card-body>
          <b-form>
            <b-row>
              <b-col>
                <base-input :label="$t('From Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs.from"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="from_date"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-col>
                <base-input :label="$t('To Date')">
                  <b-row>
                    <b-col>
                      <flat-pickr
                        :config="configs.to"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="to_date"
                      >
                      </flat-pickr>
                    </b-col>
                  </b-row>
                </base-input>
              </b-col>
              <b-col>
                <base-input :label="$t('Store')">
                  <select class="form-control" v-model="location_ID">
                    <option></option>
                    <option v-for="(loc, idx) in storelist" :key="idx" :value="idx" >
                      {{ loc.LocationName }}
                    </option>
                  </select>
                </base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="summary_only"
                  :value="true"
                  :unchecked-value="false"
                  switch
                >
                  {{ $t("Summary Only") }}
                </b-form-checkbox>
              </b-col>
              <b-col>
                <button class="btn btn-primary" @click.prevent="form_search">
                  {{ $t("Search") }}
                </button>
              </b-col>
              
              <!-- <b-col>
                <div style="display: flex;">
                  <span>
                    <a v-bind:href="pdfBaseUrl + '/Web/Report/pos_day_end_report?' + 
                      'token=' + token + 
                      '&user_id=' + user_id +
                      '&ac_time=' + this.ac_time +
                      '&FromDate=' + (this.ac_time ? this.from_date : this.from_date + ' ' + this.from_time) +
                      '&ToDate=' + (this.ac_time ? this.to_date : this.to_date + ' ' + this.to_time) +
                      '&StoreID=' + this.location_ID +
                      '&SummaryOnly=' + this.summary_only +
                      '&ComputerName=' + this.cur_computer + 
                      '&StationNo=' + this.cur_station
                      " class="btn btn-primary" target="_blank">PDF</a>
                  </span>
                  <span class="ml-6">
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="getReportName('ModifierReport', 'xlsx')">
                        <button class="btn btn-primary">XLSX</button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </span>
                </div>
              </b-col> -->
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-container>
    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
          <div v-if="list_data">
            <b-dropdown id="dropdown-1" :text='$t("Download")' variant="primary" class="m-md-2">
              <b-dropdown-item>
                <xlsx-workbook>
                  <xlsx-sheet
                    :collection="sheet.data"
                    v-for="sheet in sheets"
                    :key="sheet.name"
                    :sheet-name="sheet.name"/>
                  <xlsx-download :filename="getReportName('ModifierReport', 'xlsx')">Excel</xlsx-download>
                </xlsx-workbook>
              </b-dropdown-item>
              <!-- <b-dropdown-item>PDF</b-dropdown-item> -->
            </b-dropdown>
            <div v-bind:style="{ width: '100%' }">
              <b-row>
                <b-col>
                  <span style="font-size:x-large;">{{ $t('Search Range') + ": " }}</span>
                  <span style="font-weight: bold; font-size:x-large;"> {{ report_from_date }}</span>
                  <span style="font-size:x-large;">{{ " " + $t('To') + " " }}</span>
                  <span style="font-weight: bold; font-size:x-large;"> {{ report_to_date }}</span>
                </b-col>
                <b-col class="text-center">
                  <span style="font-size:x-large;">{{ $t('Generated on') + ": " }}</span>
                  <span style="font-weight: bold; font-size:x-large;"> {{ report_generate_time }} </span>
                </b-col>
                <b-col class="text-right">
                  <span style="font-size:x-large;">{{ $t('Last updated') + ": " }}</span>
                  <span style="font-weight: bold; font-size:x-large;"> {{ report_last_updated_time }} </span>
                </b-col>
              </b-row>
            </div>
            <div v-bind:style="{ width: report_width }">
              <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                <b-col><div v-bind:style="markStyleObject">{{ report_special_mark }}</div></b-col>
                <b-col :key='"col_name_" + col_name_key' v-for='(col_name, col_name_key) in list_data["column_name"]'>
                  <div v-bind:style="headerStyleObject">{{ col_name }}</div>
                </b-col>
              </b-row>
              <b-row :key='"modGrp_" + modGrp_key' v-for='(modGrp, modGrp_key) in list_data["column_data"]'>
                <b-col>
                  <div v-bind:style="titleStyleObject">{{ getModGrpName(modGrp_key) }}</div>
                  <div>
                    <b-row :key='"sub_row_" + sub_row_key' v-for='(sub_row, sub_row_key) in modGrp["SUMMARY"]'>
                      <b-col><div v-bind:style="nameStyleObject">{{ sub_row.Name1 + ' (' + sub_row.Name2 + ')' }}</div></b-col>
                      <b-col :key='"col_data_" + col_data_key' v-for='(col_data, col_data_key) in modGrp'>
                        <div :style="{ textAlign: 'right' }">{{ col_data[sub_row_key]['Qty'] }}</div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row> 
            </div>
          </div>
          <div v-else>
            {{ $t('No Records') }}
          </div>
        </b-card-body>
      </b-card>
    </b-container>
    <vue-element-loading
      :active="showLoading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
    
  </div>
</template>
<script>
import {
  Button
} from "element-ui";
import VueElementLoading from "vue-element-loading";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";


const axios = require("axios");

export default {
  components: {
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    VueElementLoading,
  },
  data() {
    return {
      showLoading: false,
      from_date: null,
      to_date: null,
      location_ID: null,
      storelist: null,
      summary_only: true,
      token: "",
      user_id: 0,
      user_name: null,
      only_self: null,
      report_width: null,
      report_from_date: null,
      report_to_date: null,
      report_special_mark: null,
      report_generate_time: null,
      report_last_updated_time: null,
      list_data: null,
      modifier_groups: null,
      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
      configs: {
        from: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
        to: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
      },
      markStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      headerStyleObject: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titleStyleObject: {
        textAlign: 'left',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '22px',
      },
      nameStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
      },
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    getReportName(reportName , reportType) {
      let start = this.remove_dash(this.report_from_date);
      let end = this.remove_dash(this.report_to_date);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    getModGrpName(idn) {
      for(var i=0; i<this.modifier_groups.length; i++) {
        if(this.modifier_groups[i].IDn == idn){
          return this.modifier_groups[i].Name1 + ' (' + this.modifier_groups[i].Name2 + ')';
        }
      }
      return 'Not found';
    },
    fillup_sheet() {
      // Init 2 dimensional array
      var dataarr = [];
      const excel_height = 1000;
      for (var i = 0; i < excel_height; i++) {
        dataarr[i] = [];
      }
      try {
        // Date line
        dataarr[0][0] = ('Search Range: ' + this.report_from_date + " To " + this.report_to_date);
        dataarr[1][0] = ('Generated on: ' + this.report_generate_time);
        dataarr[2][0] = ('Last updated: ' + this.report_last_updated_time);

        var x,y,y1,y2,y3;
        y = 3;
        for (var list_key in this.list_data) {
          if (this.list_data.hasOwnProperty(list_key)) {
            var list = this.list_data[list_key]
            if (list_key == 'column_name') {
              x = 0;
              dataarr[y][x++] = this.report_special_mark;
              for (var col_key in this.list_data.column_name) {
                if (this.list_data.column_name.hasOwnProperty(col_key)) {
                  dataarr[y][x++] = this.list_data.column_name[col_key];
                }      
              }
              y++;
            } else {
              for(var modGrp_key in list) {
                dataarr[y++][0] = this.getModGrpName(modGrp_key);
                x = 1;
                y2 = y;
                for(var monthOrStore_key in list[modGrp_key]) {
                  y = y2;
                  if (monthOrStore_key == 'SUMMARY') {
                    for(var mod_key in list[modGrp_key][monthOrStore_key]) {
                      dataarr[y++][0] = list[modGrp_key][monthOrStore_key][mod_key].Name1 + " (" + list[modGrp_key][monthOrStore_key][mod_key].Name2 + ")";
                      y3 = y;
                    }
                  }
                  y = y2;
                  for(var mod_key in list[modGrp_key][monthOrStore_key]) {
                    dataarr[y++][x] = list[modGrp_key][monthOrStore_key][mod_key].Qty;
                    y3 = y;
                  }
                  x++;
                }
                y = y3 + 1;
              }
            }
          }
        }
      } catch (err) {
        // console.log("fillup_sheet err",err);
      }
      // console.log("fillup_sheet",x,y,dataarr);
      var sheetname = "ModifierReport";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    async form_search() {
      this.get_list_data();
    },
    async get_list_data() {
      this.showLoading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("FromDate", this.from_date);
      bodyFormData.append("ToDate", this.to_date);
      if (this.location_ID != null) {
        bodyFormData.append("LocationID", this.location_ID);
      }
      bodyFormData.append("SummaryOnly", this.summary_only);

      var that = this;
      axios({
        method: "post",
        url: "/Api/ModifierReport",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.onlyself = rt.data.onlyself;
              that.storelist = rt.data.storelist;
              that.list_data = rt.data.list_data;
              that.report_width = String((rt.data.data_column_count + 1) * 240) + "px";
              that.report_from_date = rt.data.fromDate;
              that.report_to_date = rt.data.toDate;
              that.report_special_mark = rt.data.specialMark;
              that.modifier_groups = rt.data.modifier_groups;
              that.report_generate_time = rt.data.generate_time;
              that.report_last_updated_time = rt.data.last_updated_time;
              that.user_name = rt.data.username;
              if (that.list_data) {
                that.fillup_sheet();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    async daily_routine() {
      this.showLoading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("temp_access", true);

      var that = this;
      axios({
        method: "post",
        url: "/Api/ModifierReport/run_daily_routine",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("FINISHED run_daily_routine()");
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_records() {
      this.showLoading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/ModifierReport/init_records",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("FINISHED init_records()");
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");

      this.get_list_data();
    },

  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
  unmounted(){
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding-top: 40px;
}
</style>
