<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-tabs pills v-model="tab_idx">
              <b-tab>
                <template v-slot:title> Report 1 </template>
                <div> Comming Soon </div>
              </b-tab>

              <b-tab>
                <template v-slot:title> Report 2 </template>
                <div> Comming Soon </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  data() {
    return {
      tab_idx: 0,
    };
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
