<template>
  <div>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">Light table</h3>
            </b-card-header>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input label="Date picker">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="dates.simple"
                      >
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Date picker">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="dates.simple"
                      >
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Simple Text">
                      <b-input
                        placeholder="Alternative input"
                        class="form-control"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Example select">
                      <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input label="Example select">
                      <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Example select">
                      <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Simple Text">
                      <b-input
                        placeholder="Alternative input"
                        class="form-control"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input label="Simple Text">
                      <b-input
                        placeholder="Alternative input"
                        class="form-control"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="4">
                    <base-input type="submit" value="Search" />
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-tabs pills>
              <b-tab active>
                <template v-slot:title>
                  <i class="ni ni-cloud-upload-96 mr-2"></i> Home
                </template>
                <p class="p-3">
                  <el-table
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="projects"
                  >
                    <el-table-column
                      label="Project"
                      min-width="310px"
                      prop="name"
                    >
                      <template v-slot="{ row }">
                        <b-media no-body class="align-items-center">
                          <a href="#" class="mr-3">
                            <b-img
                              class="avatar"
                              rounded="circle"
                              alt="Image placeholder"
                              :src="row.img"
                            />
                          </a>
                          <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{
                              row.title
                            }}</span>
                          </b-media-body>
                        </b-media>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Budget"
                      prop="budget"
                      min-width="140px"
                    >
                    </el-table-column>

                    <el-table-column
                      label="Status"
                      min-width="170px"
                      prop="status"
                    >
                      <template v-slot="{ row }">
                        <badge class="badge-dot mr-4">
                          <i :class="`bg-${row.statusType}`"></i>
                          <span
                            class="status"
                            :class="`text-${row.statusType}`"
                            >{{ row.status }}</span
                          >
                        </badge>
                      </template>
                    </el-table-column>

                    <el-table-column label="Users" min-width="190px">
                      <div class="avatar-group">
                        <a
                          href="#"
                          class="avatar avatar-sm rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Ryan Tompson"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/theme/team-1.jpg"
                          />
                        </a>
                        <a
                          href="#"
                          class="avatar avatar-sm rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Romina Hadid"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/theme/team-2.jpg"
                          />
                        </a>
                        <a
                          href="#"
                          class="avatar avatar-sm rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Alexander Smith"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/theme/team-3.jpg"
                          />
                        </a>
                        <a
                          href="#"
                          class="avatar avatar-sm rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Jessica Doe"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </el-table-column>

                    <el-table-column
                      label="Completion"
                      prop="completion"
                      min-width="240px"
                    >
                      <template v-slot="{ row }">
                        <div class="d-flex align-items-center">
                          <span class="completion mr-2"
                            >{{ row.completion }}%</span
                          >
                          <div>
                            <base-progress
                              :type="row.statusType"
                              :value="row.completion"
                            />
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </p>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <i class="ni ni-bell-55 mr-2"></i> Profile
                </template>
                <p class="p-3">
                  Cosby sweater eu banh mi, qui irure terry richardson ex squid.
                  Aliquip placeat salvia cillum iphone. Seitan aliquip quis
                  cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <i class="ni ni-calendar-grid-58 mr-2"></i> Messages
                </template>
                <p class="p-3">
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth.
                </p>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import projects from "./Tables/projects";
import { Table, TableColumn } from "element-ui";
// import flatPicker from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    // flatPicker
  },
  data() {
    return {
      projects,
      currentPage: 1,
      dates: {
        simple: ""
      }
    };
  },
  methods: {},
  mounted() {}
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
