<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <!------------- list page start ------------>
      <b-row v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="2">
                <el-button type="primary" @click="add_happyhour" v-acl:HappyHourEdit.enable.show>{{
                  $t("Add")
                }}</el-button>
              </b-col>
              <b-col cols="10">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:HappyHourEdit.enable.show
                  >
                  {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button type="primary" @click="disableSort" v-acl:HappyHourEdit.enable.show>
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
            </b-row>
            <b-row>
              <b-col cols="4">
                <div v-acl:HappyHourEdit.enable.show>
                &emsp;
                  {{$t("Explanation") + ": " + $t("Sort first with highest priorityt.")}}
                </div>
              </b-col>
            </b-row>
            <p class="p-3" v-if="happyhourlist">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="happyhourlist"
                @row-click="row_clicked_event"
                ref="dragTable"
                row-key="IDn"
              >
                <el-table-column :label="$t('Name')">
                  <template v-slot="{ row }">
                    {{ row.Name1 }}-{{ row.Name2 }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Period') + '(' + $t('Time') + ')'">
                  <template v-slot="{ row }" >
                    {{ row.StartTime }}  -  {{ row.EndTime }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Period') + '(' + $t('Date') + ')'">
                  <template v-slot="{ row }">
                    {{ row.StartDate }}  -  {{ row.EndDate }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Table Type')">
                  <template v-slot="{ row }">
                    {{ row.TableType2 == '' ? 'All' :  row.TableType2 }}
                  </template>
                </el-table-column>
                <el-table-column label="Active">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.Active"
                      @change="change_row_property(row, 'Active')"
                      size="sm"
                      :disabled="!hasUtilityAuth('HappyHourEdit')"
                    >
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </b-form-select>
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <!------------- edit page start ------------>
      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
<!-- 1 line -->
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        placeholder="Name1"
                        class="form-control"
                        v-model="cur_happyhour.Name1"
                        required
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        placeholder="Name2"
                        class="form-control"
                        v-model="cur_happyhour.Name2"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Status')">
                      <select class="form-control" v-model="cur_happyhour.Active">
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Table Type')">
                      <select
                        class="form-control"
                        v-model="cur_happyhour.TableType2"
                      >
                        <option value="" :key="0">{{ "All" }}</option>
                        <option
                          v-for="(tt) in tabletypelist"
                          :key="tt"
                          :value="tt"
                        >
                          {{ tt }}
                        </option>
                      </select>
                    </base-input>
                  </el-col>
                </el-row>
<!-- 2 line -->
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <base-input :label="$t('Start Date')">
                        <el-date-picker
                          v-model="cur_happyhour.StartDate"
                          @blur="refreshpage"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="Pick a day">
                        </el-date-picker>
                      </base-input>
                    </el-col>
                    <el-col :span="6">
                      <base-input :label="$t('End Date')">
                        <el-date-picker
                          v-model="cur_happyhour.EndDate"
                           @blur="refreshpage"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="Pick a day">
                        </el-date-picker>
                      </base-input>
                    </el-col>
                </el-row>             
  <!-- check box 3 line-->               
                <el-row :gutter="10">
                  <el-col :span="24"> 
                   {{ $t('Apply Weekday') }}: &nbsp;

                        <el-checkbox
                          v-model="cur_happyhour.Week0"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Sunday')}} </el-checkbox>

                        <el-checkbox
                          v-model="cur_happyhour.Week1"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Monday')}} </el-checkbox>

                        <el-checkbox
                          v-model="cur_happyhour.Week2"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Tuesday')}} </el-checkbox>

                        <el-checkbox
                          v-model="cur_happyhour.Week3"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Wednesday')}} </el-checkbox>
                      
                      <el-checkbox
                          v-model="cur_happyhour.Week4"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Thursday')}} </el-checkbox>

                        <el-checkbox
                          v-model="cur_happyhour.Week5"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Friday')}} </el-checkbox>

                        <el-checkbox
                          v-model="cur_happyhour.Week6"
                          @change="refreshpage"
                          :true-label='1'
                          :false-label='0'
                        > {{$t('Saturday')}} </el-checkbox>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="$t('Start Time')">
                      <el-time-select
                        v-model="cur_happyhour.StartTime"
                        @blur="refreshpage"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00'
                        }"
                        placeholder="Pick Time">
                      </el-time-select>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('End Time')">
                      <el-time-select
                        v-model="cur_happyhour.EndTime"
                        @blur="refreshpage"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00'
                        }"
                        placeholder="Pick Time">
                      </el-time-select>
                    </base-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <p></p>
                </el-row>
<!-- 4 line -->
                <el-row :gutter="10" class="mt-3"  >
                  <el-col :span="6">
                    <base-input :label="$t('Apply Type')">
                      <select class="form-control" v-model="cur_happyhour.ApplyType" @change="refreshpage">
                        <option value="1">{{$t('Price Group')}}</option>
                        <option value="2">{{$t('Menu')}}</option>
                        <option value="3">{{$t('Percentage Discount of Whole Order')}}</option>
                        <option value="4">{{$t('Discount of Whole Order After Tax')}}</option>
                      </select>
                    </base-input>
                  </el-col>       
                  <el-col :span="6" v-if="cur_happyhour.ApplyType=='1'">
                    <base-input :label="$t('Price Group') + '(A-I)'">
                      <select class="form-control" v-model="datavalue1">
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                          <option value="F">F</option>
                          <option value="G">G</option>
                          <option value="H">H</option>
                          <option value="I">I</option>
                        </select>
                      </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_happyhour.ApplyType=='2'">
                    <base-input :label="$t('Menu')">
                      <select
                        class="form-control"
                        v-model="datavalue2"
                      >
                        <option
                          v-for="(menu) in menulist"
                          :key="menu.IDn"
                          :value="menu.IDn"
                        >
                          {{ menu.Name1 }}
                        </option>
                      </select>

                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_happyhour.ApplyType=='3'">
                    <base-input :label="$t('Percentage Of Whole Order Discount') + '(1-100)'">
                      <b-input
                        :placeholder="$t('Percentage Of Whole Order Discount') + '(1-100)'"
                        class="form-control"
                        v-model="datavalue3"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_happyhour.ApplyType=='4'">
                    <base-input :label="$t('Price After Discount Of Whole Order')">
                      <b-input
                        :placeholder="$t('Price After Discount Of Whole Order')"
                        class="form-control"
                        v-model="datavalue4"
                      />
                    </base-input>
                  </el-col>
                </el-row>
                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" v-if="cur_opt == 'update'">
                    <el-button type="primary" @click="form_delete">{{
                      $t("Delete")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Checkbox,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DatePicker,
  TimeSelect,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      currpage: "list",
      cur_happyhour: null,
      cur_opt: null,
      new_happyhour: {
        IDn: "",
        Name1: "",
        Name2: "",
        Active: "1",
        ApplyOrder: "",
        Week0: 0,
        Week1: 0,
        Week2: 0,
        Week3: 0,
        Week4: 0,
        Week5: 0,
        Week6: 0,
        StartTime: "00:00",
        EndTime: "00:00",
        StartDate: "",
        EndDate: "",
        TableType2: "",
        ApplyType: "1",
        DataValue: ""
      },
      happyhourlist: null,
      datavalue1:"",
      datavalue2:"",
      datavalue3:"",
      datavalue4:"",

      delay_timer: null,

      dismessage: "",
      countdown: 0,

      sortable: null,
      isSortableEnabled: false,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    // for sort --------------------start----
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    enableSort() {
      if (this.happyhourlist.length <= 0) {
        return;
      }
      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.happyhourlist);
          const targetRow = this.happyhourlist.splice(evt.oldIndex, 1)[0];
          this.happyhourlist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.happyhourlist);
        },
      });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.happyhourlist.length; i++){
        orderlist.push({IDn:this.happyhourlist[i].IDn, ApplyOrder:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/happy_hour/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.happyhourlist = rt.data.happyhours;
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // for sort ---------------end----

    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    refreshpage(value){
      this.$forceUpdate();
    },
    add_happyhour() {
      this.currpage = "edit";
      if (this.cur_happyhour == null) this.cur_happyhour = [];
      for (var key in this.new_happyhour) {
        this.cur_happyhour[key] = this.new_happyhour[key];
      }
      this.datavalue1 = "";
      this.datavalue2 = "";
      this.datavalue3 = "";
      this.datavalue4 = "";
      this.btnselectchange();
      this.cur_opt = "add";
      // set default date (start and end)
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      month = month < 10 ?  '0' + month : month;
      day = day < 10 ?  '0' + day : day;

      this.cur_happyhour.StartDate = year + '-' + month + '-' + day;
      this.cur_happyhour.EndDate = year + '-12-31';
    },
    format_week(hph) {
      hph.ApplyOrder = parseInt(hph.ApplyOrder);
      hph.Week0 = parseInt(hph.Week0);
      hph.Week1 = parseInt(hph.Week1);
      hph.Week2 = parseInt(hph.Week2);
      hph.Week3 = parseInt(hph.Week3);
      hph.Week4 = parseInt(hph.Week4);
      hph.Week5 = parseInt(hph.Week5);
      hph.Week6 = parseInt(hph.Week6);
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, event, column);
      if (!hasUtilityAuth('HappyHourEdit')) return;
      if (  column &&  column.label == "Active" ) {
        // Skip some columns by Label
        return;
      }
      this.currpage = "edit";
      this.cur_happyhour = JSON.parse(JSON.stringify(row));
      this.format_week(this.cur_happyhour)

      this.datavalue1 = "";
      this.datavalue2 = "";
      this.datavalue3 = "";
      this.datavalue4 = "";
      if(this.cur_happyhour.ApplyType == '1'){
        this.datavalue1 = this.cur_happyhour.DataValue;
      } else if(this.cur_happyhour.ApplyType == '2'){
        this.datavalue2 = this.cur_happyhour.DataValue;
      } else if(this.cur_happyhour.ApplyType == '3'){
        this.datavalue3 = this.cur_happyhour.DataValue;
      } else if(this.cur_happyhour.ApplyType == '4'){
        this.datavalue4 = this.cur_happyhour.DataValue;
      }

      this.btnselectchange();
      this.cur_opt = "update";
    },
    btnselectchange() {
      let color = this.cur_happyhour.BtnColor;
      this.btnselectstyle = { backgroundColor: color };
    },
    form_cancel() {
      this.currpage = "list";
      this.datavalue1 = "";
      this.datavalue2 = "";
      this.datavalue3 = "";
      this.datavalue4 = "";
      this.cur_happyhour = null;

      // clear sort status
      if(this.isSortableEnabled){
        this.sortable.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_happyhour_list();
      }
      
    },
    async change_row_property(row, attr) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      var that = this;
      bodyFormData.append("UpdateBy", this.user_id);
      bodyFormData.append("IDn", row["IDn"]);
      bodyFormData.append(attr, row[attr]);

      axios({
        method: "post",
        url: "/Api/Web/happy_hour/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_delete() {
      if (this.cur_opt != 'update') return;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.cur_happyhour['IDn']);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/happy_hour/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.happyhourlist = rt.data.happyhours;
              that.currpage = "list";
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event, dosomething) {
      if (event) {
        event.preventDefault();
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;

      if(this.cur_happyhour.TableType2){
          bodyFormData.append("TableType2", this.cur_happyhour.TableType2);
      } else {
        bodyFormData.append("TableType2",'');
      }

      for (var key in this.cur_happyhour) {
        if (this.cur_happyhour[key])
          bodyFormData.append(key, this.cur_happyhour[key]);
      }

    bodyFormData.append("Week0",this.cur_happyhour.Week0);
    bodyFormData.append("Week1",this.cur_happyhour.Week1);
    bodyFormData.append("Week2",this.cur_happyhour.Week2);
    bodyFormData.append("Week3",this.cur_happyhour.Week3);
    bodyFormData.append("Week4",this.cur_happyhour.Week4);
    bodyFormData.append("Week5",this.cur_happyhour.Week5);
    bodyFormData.append("Week6",this.cur_happyhour.Week6);

    if(this.cur_happyhour.ApplyType == '1'){
      bodyFormData.append("DataValue",this.datavalue1);
    } else if(this.cur_happyhour.ApplyType == '2'){
      bodyFormData.append("DataValue",this.datavalue2);
    } else if(this.cur_happyhour.ApplyType == '3'){
      bodyFormData.append("DataValue",this.datavalue3);
    } else if(this.cur_happyhour.ApplyType == '4'){
      bodyFormData.append("DataValue",this.datavalue4);
    }
      axios({
        method: "post",
        url: "/Api/Web/happy_hour/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_happyhour_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/happy_hour",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.happyhourlist = rt.data.happyhours;
              that.tabletypelist = rt.data.tabletypes;
              that.menulist = rt.data.menus;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_happyhour_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
/* .search-button {
  margin-top: 2rem;
} */
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.handshape{
  cursor: pointer;
}
</style>
