/* eslint-disable */
export const clearStore = () => {
  window.localStorage.clear();
};

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return;
  return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

export const shortName = name => {
  var x;
  var length = 12;
  if (name && name.length > length) {
    x = name.substring(0, length - 2);
    x += "...";
  } else {
    x = name;
  }
  return x;
}

export const formatName1Name2_1 = (name1, name2) => {
  return (name2 && name2 != "" ? name1 + " - " + name2 : name1);
}

export const getName1Name2_1 = obj => {
  return formatName1Name2_1(obj.Name1, obj.Name2)
}

export const getShortendName1Name2_1 = obj => {
  let name1 = shortName(obj.Name1);
  let name2 = shortName(obj.Name2);
  return formatName1Name2_1(name1, name2);
}

export const formatName1Name2_2 = (name1, name2) => {
  return name1 + (name2 ? " [" + name2 + "]" : "");
}

export const getName1Name2_2 = obj => {
  return formatName1Name2_2(obj.Name1, obj.Name2);
}

export const getShortendName1Name2_2 = obj => {
  let name1 = shortName(obj.Name1);
  let name2 = shortName(obj.Name2);
  return formatName1Name2_2(name1, name2);
}
