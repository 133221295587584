var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:[
       {'card-lift--hover': _vm.hover},
       {'shadow': _vm.shadow},
       {[`shadow-${_vm.shadowSize}`]: _vm.shadowSize},
       {[`bg-gradient-${_vm.gradient}`]: _vm.gradient},
       {[`bg-${_vm.type}`]: _vm.type}
     ],attrs:{"no-body":""}},[_vm._t("image"),(_vm.$slots.header)?_c('b-card-header',{class:_vm.headerClasses},[_vm._t("header")],2):_vm._e(),(!_vm.noBody)?_c('b-card-body',{class:_vm.bodyClasses},[_vm._t("default")],2):_vm._e(),(_vm.noBody)?_vm._t("default"):_vm._e(),(_vm.$slots.footer)?_c('b-card-footer',{class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }