<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        :variant="variant"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div>
              <el-button type="primary" @click="add_printer" v-acl:PrinterEdit.enable.show>{{
                $t("Add")
              }}</el-button>
            </div>
            <p class="p-3" v-if="printerlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="printerlist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="ID" prop="IDn">
                  <template v-slot="{ row }">
                    {{ row.IDn }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name')" prop="Name">
                </el-table-column>
                <el-table-column :label="$t('Remarks')" prop="Remarks">
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="Status" align="center">
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="form-checkbox"
                      v-model="row.Status"
                      value="1"
                      unchecked-value="2"
                      switch
                      style="margin-left: 1.6rem;"
                      @change="change_row_property(row, 'Status')"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <p>{{ cur_printer.IDn }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name')">
                      <b-input
                        placeholder="Name"
                        class="form-control"
                        v-model="cur_printer.Name"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_printer.Remarks"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Status')">
                      <select class="form-control" v-model="cur_printer.Status">
                        <option
                          v-for="(st, idx) in statuslist"
                          :key="idx"
                          :value="idx"
                        >
                          {{ st }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  data() {
    return {
      currpage: "list",
      cur_printer: null,
      cur_opt: null,
      new_printer: {
        IDn: "",
        Name: "",
        Remarks: "",
        Status: 1,
      },
      printerlist: null,
      statuslist: null,

      token: "",
      user_id: 0,

      dismessage: "",
      countdown: 0,
      variant: "success",
    };
  },
  methods: {
    hasUtilityAuth,
    add_printer() {
      this.currpage = "edit";
      this.cur_printer = this.new_printer;
      this.cur_opt = "add";
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, column, event);
      if (column && column.label == this.$t("Status")) {
        // Skip some columns by Label
        return;
      }
      if (!hasUtilityAuth('PrinterEdit')) return;
      this.currpage = "edit";
      this.cur_printer = JSON.parse(JSON.stringify(row));
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_printer) {
        if (this.cur_printer[key])
          bodyFormData.append(key, this.cur_printer[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Printer/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_row_property(row, attr) {
      // console.log("change_row_property", row, attr);
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      var that = this;
      bodyFormData.append("IDn", row["IDn"]);
      bodyFormData.append(attr, row[attr]);

      axios({
        method: "post",
        url: "/Api/Web/Printer/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_printer_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Printer",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.printerlist = rt.data.printers;
              that.statuslist = rt.data.statuses;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
        this.variant = "success";
      }
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_printer_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.search-button {
  margin-top: 2rem;
}
</style>
