<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div>
              <el-button type="primary" @click="add_employee">{{
                $t("Add")
              }}</el-button>
            </div>
            <p class="p-3" v-if="employeelist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="employeelist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="IDn" prop="IDn"> </el-table-column>
                <el-table-column :label="$t('Username')" prop="">
                  <template v-slot="{ row }">
                    {{ row.FirstName }} {{ row.LastName }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Tel')" prop="Tel">
                  <template v-slot="{ row }">
                    <span v-if="row.Ext">{{ row.Ext }}</span>
                    <span v-else-if="row.Cell">{{ row.Cell }}</span>
                    <span v-else>{{ row.Tel }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Email')"
                  prop="Email"
                ></el-table-column>
                <el-table-column
                  :label="$t('Status')"
                  prop="Status"
                ></el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <p>IDN: {{ cur_employee.IDn }}</p>
                  </b-col>
                  <b-col class="text-right">
                    Activated
                    <el-switch
                      v-model="cur_employee.Status"
                      name="Status"
                      active-value="Active"
                      inactive-value="Inactive"
                    >
                    </el-switch>
                  </b-col>
                </b-row>

                <!-- <b-row>
                  <b-col>
                    <base-input :label="$t('Status')">
                        <div class="form-control">
                        </div>
                    </base-input>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col>
                    <base-input :label="$t('First Name')">
                      <b-input
                        placeholder="FirstName"
                        class="form-control"
                        v-model="cur_employee.FirstName"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Last Name')">
                      <b-input
                        placeholder="LastName"
                        class="form-control"
                        v-model="cur_employee.LastName"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <b-input
                        placeholder="Department"
                        class="form-control"
                        v-model="cur_employee.Department"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Position')">
                      <b-input
                        placeholder="Position"
                        class="form-control"
                        v-model="cur_employee.Position"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Ext')">
                      <b-input
                        placeholder="Ext."
                        class="form-control"
                        v-model="cur_employee.TELExt"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Cel')">
                      <b-input
                        placeholder="Cel."
                        class="form-control"
                        v-model="cur_employee.Cell"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Tel')">
                      <b-input
                        placeholder="Tel."
                        class="form-control"
                        v-model="cur_employee.TEL"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Email')">
                      <b-input
                        placeholder="Email"
                        class="form-control"
                        v-model="cur_employee.EMail"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Start Date')">
                      <b-input
                        placeholder="Start Date"
                        class="form-control"
                        v-model="cur_employee.StartDate"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Address Line 1')">
                      <b-input
                        placeholder="Address Line 1"
                        class="form-control"
                        v-model="cur_employee.Address1"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Address Line 2')">
                      <b-input
                        placeholder="Address Line 2"
                        class="form-control"
                        v-model="cur_employee.Address2"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Postal Code')">
                      <b-input
                        placeholder="Postal Code"
                        class="form-control"
                        v-model="cur_employee.ZIP"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('City')">
                      <b-input
                        placeholder="City"
                        class="form-control"
                        v-model="cur_employee.City"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Province')">
                      <b-input
                        placeholder="Province"
                        class="form-control"
                        v-model="cur_employee.Province"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Country')">
                      <b-input
                        placeholder="Country"
                        class="form-control"
                        v-model="cur_employee.Country"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Birthday')">
                      <!-- <b-input
                        placeholder="Birthday"
                        class="form-control"
                        v-model="cur_employee.Birthday"
                      /> -->
                      <!-- <b-datepicker
                          v-model="cur_employee.Birthday"
                          show-week-number="false"
                          placeholder="Click to select..."
                          icon="calendar-today"
                          trap-focus>
                      </b-datepicker> -->
                      <flat-pickr
                        v-model="cur_employee.BirthDay"
                        :config="pickr_config"
                        class="form-control"
                        placeholder="Select birthday"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Gender')">
                      <b-input
                        placeholder="Gender"
                        class="form-control"
                        v-model="cur_employee.Gender"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('SIN')">
                      <b-input
                        placeholder="Social Insurance Number"
                        class="form-control"
                        v-model="cur_employee.SIN"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Wage')">
                      <b-input
                        placeholder="Wage"
                        class="form-control"
                        v-model="cur_employee.PayAmount"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_employee.Remarks"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Contact')">
                      <b-input
                        placeholder="Contact"
                        class="form-control"
                        v-model="cur_employee.EmergencyContact"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Tel')">
                      <b-input
                        placeholder="Tel."
                        class="form-control"
                        v-model="cur_employee.EmergencyTEL"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input :label="$t('Relationship')">
                      <b-input
                        placeholder="Relationship"
                        class="form-control"
                        v-model="cur_employee.EmergencyRelationship"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import { Table, TableColumn, Button, Switch } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import BaseInput from "../../components/Inputs/BaseInput.vue";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
      },
      currpage: "list",
      cur_employee: null,
      cur_employee_status: null,
      cur_opt: null,
      new_employee: {
        IDn: "",
        Department: "",
        FirstName: "",
        LastName: "",
        Position: "",
        Gender: "",
        BirthDay: null,
        SIN: "",
        TEL: "",
        TELExt: "",
        Cell: "",
        EMail: "",
        Address1: "",
        Address2: "",
        City: "",
        Province: "",
        Country: "",
        ZIP: "",
        EmergencyContact: "",
        EmergencyTEL: "",
        EmergencyRelationship: "",
        StartDate: null,
        Language: "",
        PayPeriod: "",
        PayAmount: "",
        CreateDateTime: "",
        CreateBy: "",
        UpdateDateTime: "",
        UpdateBy: "",
        Remarks: "",
        Status: "",
      },
      employeelist: null,
      onlyself: null,
      token: "",
      user_id: 0,
    };
  },
  methods: {
    add_employee() {
      this.currpage = "edit";
      this.cur_user = this.new_user;
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      this.currpage = "edit";
      this.cur_employee = JSON.parse(JSON.stringify(row));
      this.cur_employee_status = this.cur_employee.Status;
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_employee) {
        bodyFormData.append(key, this.cur_employee[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Employee/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_employee_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Employee",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.employeelist = rt.data.employees;
              that.onlyself = rt.data.onlyself;
              if (that.onlyself) {
                that.currpage = "edit";
                that.cur_employee = that.employeelist[0];
                that.cur_employee_status = that.employeelist[0].status;
                that.cur_opt = "update";
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }

      return this.get_employee_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
