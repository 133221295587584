var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
      { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
      _vm.tabNavWrapperClasses
    ]},[_c('b-nav',{staticClass:"nav-pills",class:[
        `nav-pills-${_vm.type}`,
        { 'flex-column': _vm.vertical },
        { 'justify-content-center': _vm.centered },
        _vm.tabNavClasses
      ],attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab){return _c('b-nav-item',{key:tab.id,staticClass:"active",attrs:{"data-toggle":"tab","role":"tablist","active":tab.active,"href":`#${tab.id}`,"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)}),1)],1),_c('div',{staticClass:"tab-content",class:[
      { 'tab-space': !_vm.vertical },
      { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
      _vm.tabContentClasses
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }