<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{ $t('Back') }}</el-button>
                  </b-col>
                  <!-- <b-col class="text-center">
                    <a v-bind:href="pdfBaseUrl + '/Web/Report/sales_backup_for_example?token=' + token + '&user_id=' + user_id" class="btn btn-primary" target="_blank">{{ $t("Export") }}</a>
                  </b-col> -->
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      token: "",
      user_id: 0,
    };
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    }
  },
  methods: {
    form_cancel() {
      this.$router.push("/store");
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
