<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card :title="$t('Shift Time')">
            <b-card-body>
              <b-row class="border-bottom mb-4">
                <b-col cols="2">
                  <el-input v-model="shiftName" size="mini" placeholder="Name" />
                </b-col>
                <b-col cols="2">
                  <el-select
                    v-model="startTime"
                    size="mini"
                    placeholder="Select Start Time"
                  >
                    <el-option
                      v-for="item in timeOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </b-col>
                <b-col cols="2">
                  <el-select
                    v-model="endTime"
                    size="mini"
                    placeholder="Select End Time"
                  >
                    <el-option
                      v-for="item in timeOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </b-col>
                <b-col cols="2">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="add_new_shift_time"
                    >{{ $t("Add") }}</el-button
                  >
                </b-col>
                <b-col cols="2">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="back_to_store"
                    >{{ $t("Back") }}</el-button
                  >
                </b-col>
              </b-row>
              <b-row class="border-bottom mb-2">
                <b-col>
                  <template>
                    <el-tag
                      v-for="tag in shiftlist"
                      :key="tag.Name"
                      class="mx-1"
                      closable
                      type="info"
                      @close="remove_shift_time(tag)"
                    >
                      {{ tag.Name }}: {{ tag.StartTime }} - {{ tag.EndTime }}
                    </el-tag>
                  </template>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Input, Button, TimeSelect, Select, Option, Tag } from "element-ui";
import { getStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
  },
  data() {
    return {
      value: "",
      shiftName: "",
      startTime: "",
      endTime: "",
      shiftlist: [],
      timeOptions: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      token: "",
      user_id: 0,
    };
  },
  computed: {
  },
  methods: {
    add_new_shift_time() {
      if (this.shiftName) {
        var str1 = this.shiftName.toString().toLowerCase();
        var str2;
        for (var i = 0; i < this.shiftlist.length; i++) {
          str2 = this.shiftlist[i].Name.toString().toLowerCase();
          if (str1.localeCompare(str2) == 0) {
            alert("The name is existed, please input other name");
            return;
          }
        }
      } else {
        alert("Please input name");
        return;
      }
      if (!this.startTime) {
        alert("Please input start time");
        return;
      }
      if (!this.endTime) {
        alert("Please input start time");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Name", this.shiftName);
      bodyFormData.append("StartTime", this.startTime);
      bodyFormData.append("EndTime", this.endTime);
      bodyFormData.append("opt", "add");

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/shifttime/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.shiftlist = rt.data.shiftlist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    remove_shift_time(shifttime) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", shifttime.IDn);
      bodyFormData.append("opt", "delete");

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/shifttime/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.shiftlist = rt.data.shiftlist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    back_to_store() {
      this.$router.push("/store");
    },
    async get_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/shifttime",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.shiftlist = rt.data.shiftlist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_list();
    },
  },
  mounted() {
    let name = getStore("username");
    if (name && name != undefined && name == "PKAdmin") {
      this.pkadmin = true;
    }

    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
