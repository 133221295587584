import { getStore }  from '../util/helper';

const disableElement = function(el) {
  // console.log('disableElement');
  el.style.setProperty('disabled', true);
  el.disabled = true;
  el.tabIndex = -1;
  if (el.tagName == 'A') {
    el.style.setProperty('pointer-events', 'none');
    el.style.setProperty('cursor', 'default');
  } else if (el.tagName == 'div' || el.children) {
    for (let i = 0; i < el.children.length; i++) {
      if (
        el.children[i].tagName == 'SELECT' || 
        el.children[i].tagName == 'INPUT' || 
        el.children[i].tagName == 'BUTTON' || 
        el.children[i].tagName == 'TEXTAREA' || 
        el.children[i].tagName == 'A' ||
        el.children[i].tagName == 'DIV' ||
        el.children[i].tagName == 'SPAN'
      ) {
        disableElement(el.children[i]);
      }
    }
    // if (el.tagName == 'div' && el.aria-labelledby) {
    //   console.log('el.aria-labelledby', el.aria-labelledby);
    //   let elemt = document.getElementById(el.aria-labelledby);
    //   disableElement(elemt);
    // }
  }
  // if (el.children) {
  //   for (let i = 0; i < el.children.length; i++) {
  //     disableElement(el.children[i]);
  //   }
  // }

  // const nodes = el.getElementsByTagName("SELECT");
  // console.log('nodes.length', nodes.length);
  // for (let i = 0; i < nodes.length; i++) {
  //   // disableElement(nodes[i]);
  //   nodes[i].style.setProperty('disabled', true);
  //   nodes[i].disabled = true;
  //   nodes[i].tabIndex = -1;
  // }
};
const hideElement = function(el) {
  // console.log('hideElement');
  el.style.setProperty('display', 'none');
};
const hasAuth = function(needAuth, havingAuths) {
  // console.log('hasAuth', isSuper());
  if (isSuper()) { return true; }
  if (havingAuths && havingAuths.includes(needAuth)) { return true; }
  return false;
};
const aclHandler = function(el, arg, modifiers = {}) {
  // console.log('aclHandler');
  let element_visibility = JSON.parse(getStore('element_visibility'));
  let element_utility = JSON.parse(getStore('element_utility'));
  // if (disableOnly) {
  //   // if (arg == 'test') console.log('aclHandler_1');
  //   if (!hasAuth(arg, element_utility)) {
  //     disableElement(el);
  //   }
  // } else {
  //   // if (arg == 'test') console.log('aclHandler_2');
  //   if (!hasAuth(arg, element_visibility)) {
  //     disableElement(el);
  //     hideElement(el);
  //   }
  // }

  // if (arg == 'test') console.log('document', document);
  // if (arg == 'test') console.log('this', this);
  // if (arg == 'test') console.log('el', el);
  // if (arg == 'test') console.log('el.ariaLabel', el.ariaLabel);
  // if (arg == 'test') console.log('el.ariaLabelledby', el.ariaLabelledby);
  // if (arg == 'test') console.log('el.tagName', el.tagName);
  
  if (modifiers.enable) {
    if (!hasAuth(arg, element_utility)) {
      if (modifiers.show) {
        hideElement(el);
        disableElement(el);
      } else if (!modifiers.hide){
        disableElement(el);
      }
    } else {
      if (modifiers.hide) {
        hideElement(el);
        disableElement(el);
      } 
    }
  } else if (modifiers.hide) {
    if (hasAuth(arg, element_visibility)) {
      disableElement(el);
      hideElement(el);
    }
  } else {
    if (!hasAuth(arg, element_visibility)) {
      disableElement(el);
      hideElement(el);
    }
  }
};

export const isSuper = function() {
  let username = getStore('username');
  if (username != null && username.toLowerCase() == 'pkadmin') return true;
  return false;
}

export const hasVisibilityAuth = function(needAuth) {
  let element_visibility = JSON.parse(getStore('element_visibility'));
  let rtn = hasAuth(needAuth, element_visibility);
  // console.log('hasVisibilityAuth', needAuth, rtn);
  return rtn;
}

export const hasUtilityAuth = function(needAuth) {
  let element_utility = JSON.parse(getStore('element_utility'));
  let rtn = hasAuth(needAuth, element_utility);
  // if (needAuth == 'StaffEdit') console.log('hasUtilityAuth', needAuth, rtn);
  return rtn;
}

export default {
  bind: function(el, binding, vnode) {
    // console.log('bind', binding.arg);
    // if (binding.arg == 'test') console.log('bind', binding.arg);
    // // aclHandler(el, binding.arg, binding.modifiers.disable);
    aclHandler(el, binding.arg, binding.modifiers);
  },

  // inserted: function(el, binding, vnode) {
  //   // console.log('inserted', binding.arg);
  //   // if (binding.arg == 'test') console.log('inserted', binding.arg);
  //   aclHandler(el, binding.arg, binding.modifiers);
  // },

  // update: function(el, binding, vnode, oldVnode) {
  //   // console.log('update', binding.arg);
  //   // if (binding.arg == 'test') console.log('update', binding.arg);
  //   aclHandler(el, binding.arg, binding.modifiers);
  // },

  // componentUpdated: function(el, binding, vnode, oldVnode) {
  //   // console.log('componentUpdated', binding.arg);
  //   // if (binding.arg == 'test') console.log('componentUpdated', binding.arg);
  //   aclHandler(el, binding.arg, binding.modifiers);
  // },
};