<template>
  <div class="autocomplete">
    <b-input
      type="text"
      v-model="search"
      @input="onChange"
      :placeholder="placeholdertxt"
      autocomplete="off"
    />
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="result in results"
        :key="result.key"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        {{ result.value }}
      </li>
    </ul>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
const axios = require("axios");

export default {
  name: "SearchAutocomplete",
  props: {
    placeholdertxt: {
      type: String,
      required: false,
      default: "",
    },
    url: {
      type: String,
      required: true,
      default: "",
    },
    initString: {
      type: String,
      required: false,
      default: "",
    },
    whereFrom: {
      type: String,
      required: false,
      default: "",
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      resultSet: false,

      delay_timer: false,

      token: "",
      user_id: "",
    };
  },
  methods: {
    onChange() {
      this.$emit("keyword", this.search);
      this.resultSet = false;
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.searchResults();
      }, 500);
    },
    searchResults() {
      if (this.search == "") {
        this.isOpen = false;
        this.results = [];
        return;
      }

      this.$emit("changing", this.search);

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Search", this.search);
      bodyFormData.append("Limit", 20);

      var that = this;
      axios({
        method: "post",
        url: this.url,
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.results = rt.data.results;
              that.isOpen = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert("Search Error");
            // this.$router.push("/login");
          }
        })
        .catch(function (error) {
          alert("Search Error !");
          // console.log(error);
          // that.$router.push("/login");
        });
    },
    setResult(result) {
      this.search = result.value;
      if(this.whereFrom == 'PO' || this.whereFrom == 'RE'){
        this.$emit("input", result);
      } else {
        this.$emit("input", result.key);
      }
      this.resultSet = true;
      this.isOpen = false;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.$emit("changing", this.search);
        this.isOpen = false;
        if((this.whereFrom == 'PO' || this.whereFrom == 'RE') && !this.resultSet){
          if(this.selected){
            this.search = this.initString;
          } else {
            this.search = '';
          }
        }
      }
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  background-color: white;
  width: auto;
  z-index: 100;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>