<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-tabs pills v-model="tab_idx" @input="tab_clicked">
              <b-tab>
                <template v-slot:title>{{ $t("Tax Class") }}</template>
                <div v-if="currpage == 'list'">
                  <p class="p-3" v-if="tax_typelist">
                    <el-table
                      stripe
                      class="table-responsive table"
                      header-row-class-name="thead-light"
                      :data="tax_typelist"
                      @row-click="row_clicked_event_tax_type"
                    >
                      <el-table-column v-if="false" label="IDn" prop="IDn">
                        <template v-slot="{ row }">
                          {{ row.IDn }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Name')" prop="Name">
                      </el-table-column>
                      <el-table-column :label="$t('Remarks')" prop="Remarks">
                      </el-table-column>
                    </el-table>
                  </p>
                  <p class="p-3" v-else>
                    {{ $t("No Records") }}
                  </p>
                  <p class="p-3">
                    <el-button type="primary" @click="add_tax" v-acl:TaxEdit.enable.show>{{
                      $t("Add")
                    }}</el-button>
                  </p>
                </div>
                <div v-if="currpage == 'edit'">
                  <b-form>
                    <b-row v-if="cur_tax_type.IDn != 0">
                      <b-col>
                        <p>{{ cur_tax_type.IDn }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Name')">
                          <b-input
                            placeholder="Name"
                            class="form-control"
                            v-model="cur_tax_type.Name"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Remarks')">
                          <b-input
                            placeholder="Remarks"
                            class="form-control"
                            v-model="cur_tax_type.Remarks"
                          />
                        </base-input>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_cancel">{{
                          $t("Back")
                        }}</el-button>
                      </b-col>
                      <b-col class="text-center">
                        <el-button
                          type="primary"
                          @click="form_submit_tax_type"
                          >{{ $t("Submit") }}</el-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>

              <b-tab id="tax_id" lazy>
                <template v-slot:title>{{ $t("Tax") }}</template>
                <div v-if="currpage == 'list'">
                  <p class="p-3" v-if="fulltaxlist">
                    <el-table
                      stripe
                      class="table-responsive table"
                      header-row-class-name="thead-light"
                      :data="fulltaxlist"
                      @row-click="row_clicked_event_tax"
                    >
                      <el-table-column label="ID" prop="ID"> </el-table-column>
                      <el-table-column :label="$t('Tax Name')" prop="TaxName">
                      </el-table-column>
                      <el-table-column :label="$t('Tax Type')" prop="TaxType">
                      </el-table-column>
                      <el-table-column :label="$t('Tax Value')" prop="TaxValue" :formatter="taxvalue_formatter">
                      </el-table-column>
                      <el-table-column :label="$t('Value Type')" prop="ValueType">
                      </el-table-column>
                      <el-table-column :label="$t('Enabled')" prop="Enabled" :formatter="enabled_formatter">
                      </el-table-column>
                      <el-table-column :label="$t('Remarks')" prop="Remarks">
                      </el-table-column>
                    </el-table>
                  </p>
                  <p class="p-3" v-else>
                    {{ $t("No Records") }}
                  </p>
                  <p class="p-3">
                    <el-button type="primary" @click="add_tax" v-acl:TaxEdit.enable.show>{{
                      $t("Add")
                    }}</el-button>
                  </p>
                </div>
                <div v-if="currpage == 'edit'">
                  <b-form>
                    <b-row v-if="cur_tax.ID">
                      <b-col>
                        <base-input label="ID">
                          <b-input
                            placeholder="ID"
                            class="form-control"
                            v-model="cur_tax.ID"
                            :readonly="cur_opt == 'update'"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Name')">
                          <b-input
                            placeholder="Name"
                            class="form-control"
                            v-model="cur_tax.TaxName"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Type')">
                          <select
                            class="form-control"
                            v-model="cur_tax.TaxType"
                          >
                            <option value="HST">HST</option>
                            <option value="PST">PST</option>
                            <option value="GST">GST</option>
                            <option value="GST">QST</option>
                            <option value="LQT">{{ $t("Liquor Tax") }}</option>
                            <option value="DPT">{{ $t("Deposit") }}</option>
                            <option value="EHF">
                              {{ $t("Environmental Handling Fees") }}
                            </option>
                            <option value="CRF">
                              {{ $t("Container Recycling Fees") }}
                            </option>
                            <option value="TAX">{{ $t("Other TAX") }}</option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Tax Value')">
                          <b-input
                            placeholder="Tax Value"
                            class="form-control"
                            v-model="cur_tax.TaxValue"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Value Type')">
                          <select
                            class="form-control"
                            v-model="cur_tax.ValueType"
                          >
                            <option>%</option>
                            <option>$</option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Enabled')">
                          <select
                            class="form-control"
                            v-model="cur_tax.Enabled"
                          >
                            <option value="true">Enabled</option>
                            <option value="false">Disabled</option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Taxabled')">
                          <select class="form-control" v-model="cur_tax.InTax">
                            <option value="0">{{ $t("No") }}</option>
                            <option value="1">{{ $t("Yes") }}</option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Remarks')">
                          <b-input
                            placeholder="Remarks"
                            class="form-control"
                            v-model="cur_tax.Remarks"
                          />
                        </base-input>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_cancel">{{
                          $t("Back")
                        }}</el-button>
                      </b-col>
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_submit_tax">{{
                          $t("Submit")
                        }}</el-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>

              <b-tab lazy>
                <template v-slot:title>{{
                  $t("Tax Class by Province")
                }}</template>
                <b-row class="mt-3">
                  <b-col>
                    <b-input-group :prepend="$t('Name') + ' : '">
                      <b-form-input
                        v-model="cur_Name"
                        @keyup="set_filter"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input-group :prepend="$t('Country') + ' : '">
                      <select
                        class="form-control"
                        v-model="cur_CountryIDn"
                        @change="changed_country_2"
                      >
                        <option :key="0" :value="0"></option>
                        <option
                          v-for="(dt, idx) in countrylist"
                          :key="idx"
                          :value="dt.IDn"
                        >
                          {{ dt.Country }}
                        </option>
                      </select>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input-group :prepend="$t('Province') + ' : '">
                      <select
                        class="form-control"
                        v-model="cur_ProvinceIDn"
                        @change="set_filter"
                      >
                        <option :key="0" :value="0"></option>
                        <option
                          v-for="(dt, idx) in cur_provincelist_2"
                          :key="idx"
                          :value="dt.IDn"
                        >
                          {{ dt.Province }}
                        </option>
                      </select>
                    </b-input-group>
                  </b-col>
                  <!-- <b-col> </b-col> -->
                  <b-col> </b-col>
                </b-row>
                <div v-if="currpage == 'list'">
                  <p class="p-3" v-if="tax_grouplist">
                    <el-table
                      stripe
                      class="table-responsive table"
                      header-row-class-name="thead-light"
                      :data="tax_grouplist"
                      @row-click="row_clicked_event_tax_group"
                    >
                      <el-table-column v-if="false" label="IDn" prop="IDn">
                        <template v-slot="{ row }">
                          {{ row.IDn }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Name')" prop="Name">
                      </el-table-column>
                      <el-table-column :label="$t('Tax Class')">
                        <template v-slot="{ row }">
                          {{ get_tax_type_name(row.TaxTypeIDn) }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Province')">
                        <template v-slot="{ row }">
                          {{ provincelist[row.ProvinceIDn].Province }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Tax List')" prop="TaxList" :formatter="taxlist_formatter">
                      </el-table-column>
                      <el-table-column :label="$t('Status')" prop="Status">
                        <template v-slot="{ row }">
                          {{ tax_group_status[row.Status] }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('Remarks')" prop="Remarks">
                      </el-table-column>
                    </el-table>
                  </p>
                  <p class="p-3" v-else>
                    {{ $t("No Records") }}
                  </p>
                  <p class="p-3">
                    <el-button type="primary" @click="add_tax" v-acl:TaxEdit.enable.show>{{
                      $t("Add")
                    }}</el-button>
                  </p>
                </div>
                <div v-if="currpage == 'edit'">
                  <b-form>
                    <b-row>
                      <b-col>
                        <p>{{ cur_tax_group.IDn }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Name')">
                          <b-input
                            placeholder="Name"
                            class="form-control"
                            v-model="cur_tax_group.Name"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Tax Class')">
                          <select
                            class="form-control"
                            v-model="cur_tax_group.TaxTypeIDn"
                          >
                            <option
                              v-for="tax_type in tax_typelist"
                              :key="tax_type.IDn"
                              :value="tax_type.IDn"
                            >
                              {{ tax_type.Name }}
                            </option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Country')">
                          <select
                            class="form-control"
                            v-model="cur_tax_group.CountryIDn"
                          >
                            <option
                              v-for="(country, countryIDn) in countrylist"
                              :key="countryIDn"
                              :value="countryIDn"
                              @change="changed_country"
                            >
                              {{ country.Country }}
                            </option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Province')">
                          <select
                            class="form-control"
                            v-model="cur_tax_group.ProvinceIDn"
                          >
                            <option
                              v-for="(
                                province, ProvinceIDn
                              ) in cur_provincelist"
                              :key="ProvinceIDn"
                              :value="ProvinceIDn"
                            >
                              {{ province["Province"] }}
                            </option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          :label="$t('Tax List')"
                        >
                          <el-tag
                            v-for="tax in taxlist"
                            :key="tax.ID"
                            :value="tax.ID"
                            :style="el_tag_style_object[tax.ID]"
                            class="tax-tag"
                            @click.native="tag_clicked(tax.ID)">{{ 
                              format_taxinfo(tax)
                            }}
                          </el-tag>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Remarks')">
                          <b-input
                            placeholder="Remarks"
                            class="form-control"
                            v-model="cur_tax_group.Remarks"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Status')">
                          <select
                            class="form-control"
                            v-model="cur_tax_group.Status"
                          >
                            <option
                              v-for="(st, idx) in tax_group_status"
                              :key="idx"
                              :value="idx"
                            >
                              {{ st }}
                            </option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_cancel">{{
                          $t("Back")
                        }}</el-button>
                      </b-col>
                      <b-col class="text-center">
                        <el-button
                          type="primary"
                          @click="form_submit_tax_group"
                          >{{ $t("Submit") }}</el-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Tag, } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tag.name]: Tag,
  },
  data() {
    return {
      tab_idx: 0,
      currpage: "list",
      cur_tax_type: null,
      cur_tax: null,
      cur_tax_group: null,
      cur_opt: null,
      cur_CountryIDn: 0,
      cur_ProvinceIDn: 0,
      cur_Name: "",

      new_tax_type: {
        IDn: "",
        Name: "",
        Status: 1,
        Remarks: "",
      },
      new_tax: {
        ID: "",
        TaxName: "",
        TaxType: "GST",
        TaxValue: "",
        ValueType: "%",
        Enabled: "true",
        InTax: 0,
        Remarks: "",
      },
      new_tax_group: {
        IDn: "",
        Name: "",
        TaxTypeIDn: 1,
        TaxList: "",
        ProvinceIDn: 1,
        CountryIDn: 1,
        Remarks: "",
        Status: "1",
      },
      fulltaxlist: null,
      taxlist: null,
      tax_grouplist: null,
      all_tax_grouplist: null,
      tax_typelist: null,
      tax_group_status: [],
      countrylist: null,
      provincelist: null,
      cur_provincelist: null,
      cur_provincelist_2: null,

      cur_tax_checked: [],
      el_tag_unchecked: {
        color: "#409EFF",
        borderColor: "rgba(64,158,255,.2)",
        borderColorHover: "rgba(64,158,255,.3)",
        backgroundColor: "rgba(64,158,255,.1)",
        backgroundColorHover: "rgba(64,158,255,.2)",
      },
      el_tag_checked: {
        color: "white",
        borderColor: "rgba(64,158,255)",
        borderColorHover: "rgba(64,158,255,.8)",
        backgroundColor: "rgba(64,158,255,.8)",
        backgroundColorHover: "rgba(64,158,255,.7)",
      },

      token: "",
      user_id: 0,
    };
  },
  computed: {
    el_tag_style_object: function () {
      return this.get_el_tag_style_object_array();
    },
  },
  methods: {
    hasUtilityAuth,
    format_taxinfo(tax) {
      let tax_name = tax.TaxName;
      let tax_value = parseFloat(tax.TaxValue).toString();
      let tax_value_type = tax.ValueType;
      return tax_name + (
        tax_value_type == "$" ? "(" + tax_value_type + tax_value + ")" :
        tax_value_type == "%" ? "(" + tax_value + tax_value_type + ")" :
        ""
      );
    },
    format_taxlist(taxlist) {
      let tax_list = taxlist.split(",");
      let tax_info_list = "";
      for (let i = 0; i < tax_list.length; i++) {
        if (!tax_list[i]) continue;
        let tax;
        for (let j = 0; j < this.fulltaxlist.length; j++) {
          if (this.fulltaxlist[j].ID == tax_list[i]) {
            tax = this.fulltaxlist[j];
            break;
          }
        }
        let tax_info;
        if (tax) {
          tax_info = this.format_taxinfo(tax);
        } else {
          tax_info = tax_list[i] + "(not found)";
        }
        tax_info_list += (tax_info_list ? "," + tax_info : tax_info);
      }
      return tax_info_list;
    },
    taxlist_formatter(row, column) {
      return this.format_taxlist(row.TaxList);
    },
    taxvalue_formatter(row, column) {
      return parseFloat(row.TaxValue).toString();
    },
    enabled_formatter(row, column) {
      return row.Enabled == "true" ? "Enabled" : row.Enabled == "false" ? "Disabled" : "";
    },
    get_el_tag_style_object_array() {
      let rtn = {};
      for (const p in this.cur_tax_checked) {
        rtn[p] = this.get_el_tag_style_object(this.cur_tax_checked[p]);
      }
      return rtn;
    },
    get_el_tag_style_object (checked = false) {
      return checked ? 
        {
          '--color': this.el_tag_checked.color,
          '--border-color': this.el_tag_checked.borderColor,
          '--border-color-hover': this.el_tag_checked.borderColorHover,
          '--background-color': this.el_tag_checked.backgroundColor,
          '--background-color-hover': this.el_tag_checked.backgroundColorHover,
        } : {
          '--color': this.el_tag_unchecked.color,
          '--border-color': this.el_tag_unchecked.borderColor,
          '--border-color-hover': this.el_tag_unchecked.borderColorHover,
          '--background-color': this.el_tag_unchecked.backgroundColor,
          '--background-color-hover': this.el_tag_unchecked.backgroundColorHover,
        }
    },
    duplicate_object(obj) {
      let tmp = {};
      for (const p in obj){
        tmp[p] = obj[p];
      }
      return tmp;
    },
    get_cur_tax_checked(that) {
      that.cur_tax_checked = {};
      for (const tax in that.taxlist) {
        that.cur_tax_checked[that.taxlist[tax]["ID"]] = 
          (that.cur_tax_group && that.cur_tax_group["TaxList"].includes(that.taxlist[tax]["ID"])) ? 
          true : false;
      }
    },
    tax_checked_to_list() {
      let tax_list = [];
      for (const p in this.cur_tax_checked) {
        if (this.cur_tax_checked[p]){
          tax_list.push(p);
        }
      }
      return tax_list;
    },
    tag_clicked(tax_idn) {
      this.cur_tax_checked[tax_idn] = !this.cur_tax_checked[tax_idn];
      this.cur_tax_checked = this.duplicate_object(this.cur_tax_checked);
      this.cur_tax_group.TaxList = this.tax_checked_to_list();
    },
    tab_clicked() {
      this.currpage = "list";

      if (this.tab_idx == 1) {
        setStore("active_page", "tax_tax");
      } else if (this.tab_idx == 2) {
        setStore("active_page", "tax_group");
      } else {
        setStore("active_page", "tax_type");
      }
    },
    set_filter() {
      this.tax_grouplist = [];
      for (var i = 0; i < this.all_tax_grouplist.length; i++) {
        if (this.cur_ProvinceIDn != 0) {
          if (this.cur_ProvinceIDn != this.all_tax_grouplist[i].ProvinceIDn) {
            continue;
          }
        }
        else if (this.cur_CountryIDn != 0) {
          if (this.cur_CountryIDn != this.all_tax_grouplist[i].CountryIDn) {
            continue;
          }
        }
        if (this.cur_Name != "") {
          if (this.all_tax_grouplist[i].Name.toLowerCase().search(this.cur_Name.toLowerCase()) < 0) {
            continue;
          }
        }
        this.tax_grouplist.push(this.all_tax_grouplist[i]);
      }
    },
    add_tax() {
      this.currpage = "edit";
      this.cur_tax_type = JSON.parse(JSON.stringify(this.new_tax_type));
      this.cur_tax = JSON.parse(JSON.stringify(this.new_tax));
      this.cur_tax_group = JSON.parse(JSON.stringify(this.new_tax_group));
      this.cur_tax_group.TaxList = [];
      this.changed_country();
      this.get_cur_tax_checked(this);
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      this.currpage = "edit";
      this.cur_tax_type = JSON.parse(JSON.stringify(this.new_tax_type));
      this.cur_tax_group = JSON.parse(JSON.stringify(this.new_tax_group));
      this.cur_tax = JSON.parse(JSON.stringify(this.new_tax));
      this.cur_opt = "update";
    },
    row_clicked_event_tax_type(row, event, column) {
      if (!hasUtilityAuth('TaxEdit')) return;
      this.row_clicked_event(row, event, column);
      this.cur_tax_type = JSON.parse(JSON.stringify(row));
    },
    row_clicked_event_tax(row, event, column) {
      if (!hasUtilityAuth('TaxEdit')) return;
      this.row_clicked_event(row, event, column);
      this.cur_tax = JSON.parse(JSON.stringify(row));
    },
    row_clicked_event_tax_group(row, event, column) {
      if (!hasUtilityAuth('TaxEdit')) return;
      this.row_clicked_event(row, event, column);
      this.cur_tax_group = JSON.parse(JSON.stringify(row));
      if (typeof this.cur_tax_group.TaxList == "string") {
        this.cur_tax_group.TaxList = this.cur_tax_group.TaxList.split(",");
      } else if (typeof this.cur_tax_group.TaxList != "object") {
        this.cur_tax_group.TaxList = [];
      }
      this.changed_country();
      this.get_cur_tax_checked(this);
    },
    get_tax_type_name(idn) {
      for (var i = 0; i < this.tax_typelist.length; i++) {
        if (this.tax_typelist[i].IDn == idn) {
          return this.tax_typelist[i].Name;
        }
      }
      return "";
    },
    form_cancel() {
      this.currpage = "list";
    },
    changed_country() {
      if (
        !this.cur_tax_group.CountryIDn ||
        this.cur_tax_group.CountryIDn == undefined ||
        this.cur_tax_group.CountryIDn <= 0
      ) {
        this.cur_provincelist = this.provincelist;
        return;
      }

      this.cur_provincelist = {};
      for (var idx in this.provincelist) {
        if (
          this.provincelist[idx].CountryIDn == this.cur_tax_group.CountryIDn
        ) {
          this.cur_provincelist[idx] = this.provincelist[idx];
        }
      }
    },
    changed_country_2() {
      this.cur_ProvinceIDn = 0;
      if (
        !this.cur_CountryIDn ||
        this.cur_CountryIDn == undefined ||
        this.cur_CountryIDn <= 0
      ) {
        this.cur_provincelist_2 = this.provincelist;
        this.set_filter();
        return;
      }

      this.cur_provincelist_2 = {};
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == this.cur_CountryIDn) {
          this.cur_provincelist_2[idx] = this.provincelist[idx];
        }
      }
      this.set_filter();
    },
    async form_submit_tax_type(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_tax_type) {
        if (this.cur_tax_type[key])
          bodyFormData.append(key, this.cur_tax_type[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Tax_type/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              setStore("active_page", "tax_type");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit_tax(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_tax) {
        if (this.cur_tax[key]) bodyFormData.append(key, this.cur_tax[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Tax/edit_tax",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              setStore("active_page", "tax_tax");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit_tax_group(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_tax_group) {
        if (this.cur_tax_group[key])
          bodyFormData.append(key, this.cur_tax_group[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Tax/edit_tax_group",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              setStore("active_page", "tax_group");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_tax_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Tax",
        data: bodyFormData,
      })
        .then(function (response) {
          // console.log(response);
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.fulltaxlist = rt.data.all_taxs;
              that.taxlist = rt.data.taxs;
              that.all_tax_grouplist = rt.data.tax_groups;
              that.tax_grouplist = JSON.parse(
                JSON.stringify(that.all_tax_grouplist)
              );
              that.tax_typelist = rt.data.tax_types;
              that.countrylist = rt.data.countrys;
              that.provincelist = rt.data.provinces;
              that.cur_provincelist = rt.data.cur_provinces;
              that.changed_country_2();
              that.tax_group_status = rt.data.tax_group_status;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }

      let active_page = getStore("active_page");

      if (active_page == "tax_tax") {
        this.tab_idx = 1;
      } else if (active_page == "tax_group") {
        this.tab_idx = 2;
      }
      return this.get_tax_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.select_text {
  margin-left: -1rem;
}
.content {
  padding-top: 80px;
}

span.el-tag {
  margin: 3px;
  color: #409EFF;
  border-color: rgba(64,158,255,.2);
  background-color: rgba(64,158,255,.1);
}
span.el-tag:hover {
  color: #409EFF;
  border-color: rgba(64,158,255,.3);
  background-color: rgba(64,158,255,.2);
}

span.tax-tag {
  color: var(--color);
  border-color: var(--border-color);
  background-color: var(--background-color);
}
span.tax-tag:hover {
  color: var(--color);
  border-color: var(--border-color-hover);
  background-color: var(--background-color-hover);
}
</style>
