<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="closeAlert"
        class="show_alert"
      >
        {{ disMessage }}
      </b-alert>
      <b-alert
        v-model="showErrorMessage"
        variant="danger"
        dismissible
      >
        {{errorMessage}}
      </b-alert>
      
      <el-progress class="mb-1" :percentage="sync_percentage" :stroke-width="26" :text-inside="true" v-if="synchronization_in_progress"></el-progress>
      <span class="ml-2" v-if="synchronization_in_progress">{{ syncMessage }}</span>

      <div class="mt-3" style="max-width: 1400px; margin: auto">
        <b-card header-tag="header">
          <template #header>
            <h1 class="mb-0">{{ $t("Cloud Store") }}</h1>
          </template>
          <template>
            <el-table
              class="table-responsive table"
              :data="cloud_stores"
              style="width: 100%"
              border
              header-cell-class-name="my-header">
              <el-table-column
                :label="$t('Store')"
                prop="LocationID">
              </el-table-column>
              <el-table-column
                :label="$t('Menu')">
                <template slot-scope="scope">
                  {{ getMenuNameByIDn(scope.row.MenuIDn) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Status')"
                prop="Online">
                <template slot-scope="scope">
                  <el-tag
                    v-if="parseInt(scope.row.Online) == 1"
                    disable-transitions
                  >Online</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Action')"
                align="right">
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button
                      icon="el-icon-upload"
                      size="medium"
                      v-if="parseInt(scope.row.Online) == 1"
                      v-acl:CloudStoreEdit.enable.show
                      @click="handleSyncWarning(scope.$index, scope.row)"
                    >
                      Sync
                    </el-button>
                    <el-button
                      icon="el-icon-edit"
                      size="medium"
                      v-acl:CloudStoreEdit.enable.show
                      @click="handleEditStore(scope.$index, scope.row)"
                    >
                      Edit
                    </el-button>
                  </el-button-group>
                  
                </template>
              </el-table-column>
            </el-table>
          </template>
          
          <el-dialog
            :title="$t('Cloud Store Setting')"
            :visible.sync="dialogVisible"
            width="70%">
            <span>{{ $t('Store') + ': ' }}{{ cloud_store.LocationID }}</span>
            <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="handleSwitchTab">
              <el-tab-pane name="api">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-api" class="dot" :style="dot_tab_api_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ 'API' }}</span>
                  </span>
                </template>
                <b-form>
                  <b-row>
                    <b-col cols="2" class="text-right">
                      <label class="mt-2 mr-sm-2">{{ 'URL:'}}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the base URL of your cloud store.")'
                      >
                        <b-form-input
                          id="url_b_input"
                          v-model="cloud_store.URL"
                          placeholder="URL"
                        ></b-form-input>
                        <span id="url_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2" class="text-right">
                      <label class="mt-2 mr-sm-2">{{ $t("API Username") + ":"}}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the API username.")'
                      >
                        <b-form-input
                          id="username_b_input"
                          v-model="cloud_store.Username"
                          :placeholder='$t("API Username")'
                        ></b-form-input>
                        <span id="username_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2" class="text-right">
                      <label class="mt-2 mr-sm-2">{{ $t("API Key") + ":" }}</label>
                    </b-col>
                    <b-col cols="9">
                      <b-form-group
                        :description='$t("Please enter the API key.")'
                      >
                        <b-form-textarea
                          id="key_b_input"
                          v-model="cloud_store.Key"
                          :placeholder='$t("API Key")'
                          rows="1"
                          max-rows="6"
                        ></b-form-textarea>
                        <span id="key_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </el-tab-pane>
              <el-tab-pane name="menu">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-menu" class="dot" :style="dot_tab_menu_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ $t('Menu') }}</span>
                  </span>
                </template>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t('Menu') + ':'}}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the menu you would like to use.")'
                    >
                      <el-select id="menu_el_select" v-model="cloud_store.MenuIDn" placeholder="Select" style="width: 100% !important">
                        <el-option
                          v-for="item in menu_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span id="menu_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </el-tab-pane>
              <el-tab-pane name="table">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-table" class="dot" :style="dot_tab_table_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ $t('Table') }}</span>
                  </span>
                </template>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t('Delivery') + ':'}}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the table for delivery orders.")'
                    >
                      <el-select id="tableDelivery_el_select" v-model="cloud_store.TableDelivery" placeholder="Select" style="width: 100% !important">
                        <el-option
                          v-for="item in table_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span id="tableDelivery_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t('Pick Up') + ':'}}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the table for pick-up orders.")'
                    >
                      <el-select id="tablePickUp_el_select" v-model="cloud_store.TablePickUp" placeholder="Select" style="width: 100% !important">
                        <el-option
                          v-for="item in table_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span id="tablePickUp_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </el-tab-pane>
              <el-tab-pane name="language">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-language" class="dot" :style="dot_tab_language_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ $t('Language') }}</span>
                  </span>
                </template>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Name") + "1:" }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the language being used in Name 1.")'
                    >
                      <el-select id="lang1_el_select" v-model="cloud_store.Lang1" placeholder="Select" style="width: 100% !important">
                        <el-option
                          v-for="item in lang_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span id="lang1_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Name") + "2:" }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the language being used in Name 2.")'
                    >
                      <el-select id="lang2_el_select" v-model="cloud_store.Lang2" placeholder="Select" style="width: 100% !important">
                        <el-option
                          v-for="item in lang_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span id="lang2_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </el-tab-pane>
              <el-tab-pane name="hours">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px">{{ $t('Store Hours') }}</span>
                  </span>
                </template>
                <b-row>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col class="col-12 mb-2">
                        {{ $t("You can first apply the same business hours to all days, then edit the business hours for a specific day") }}
                        <el-button style="margin-left: 20px; padding: 8px 3px" type="primary" @click="handleClickSetAll()">
                          {{ $t("Set All") }}
                        </el-button>
                      </b-col>
                      <!-- MONDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Monday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-monday-business-hours"
                                  v-model="storeHours.Monday.business_hours_status"
                                  name="checkbox-monday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Monday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Monday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Monday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-monday-delivery-hours"
                              v-model="storeHours.Monday.delivery_hours_status"
                              name="checkbox-monday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Monday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Monday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Monday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- TUESDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Tuesday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-tuesday-business-hours"
                                  v-model="storeHours.Tuesday.business_hours_status"
                                  name="checkbox-tuesday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Tuesday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Tuesday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Tuesday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-tuesday-delivery-hours"
                              v-model="storeHours.Tuesday.delivery_hours_status"
                              name="checkbox-tuesday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Tuesday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Tuesday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Tuesday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- WEDNESDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Wednesday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-wednesday-business-hours"
                                  v-model="storeHours.Wednesday.business_hours_status"
                                  name="checkbox-wednesday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Wednesday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Wednesday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Wednesday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-wednesday-delivery-hours"
                              v-model="storeHours.Wednesday.delivery_hours_status"
                              name="checkbox-wednesday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Wednesday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Wednesday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Wednesday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- THURSDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Thursday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-thursday-business-hours"
                                  v-model="storeHours.Thursday.business_hours_status"
                                  name="checkbox-thursday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Thursday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Thursday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Thursday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-thursday-delivery-hours"
                              v-model="storeHours.Thursday.delivery_hours_status"
                              name="checkbox-thursday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Thursday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Thursday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Thursday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- FRIDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Friday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-friday-business-hours"
                                  v-model="storeHours.Friday.business_hours_status"
                                  name="checkbox-friday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Friday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Friday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Friday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-friday-delivery-hours"
                              v-model="storeHours.Friday.delivery_hours_status"
                              name="checkbox-friday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Friday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Friday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Friday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- SATURDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Saturday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-saturday-business-hours"
                                  v-model="storeHours.Saturday.business_hours_status"
                                  name="checkbox-saturday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Saturday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Saturday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Saturday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-saturday-delivery-hours"
                              v-model="storeHours.Saturday.delivery_hours_status"
                              name="checkbox-saturday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Saturday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Saturday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Saturday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- SUNDAY -->
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-3">
                            <b-row>
                              <b-col class="col-6 text-center">
                                <span>{{ $t('Sunday') }}</span>
                              </b-col>
                              <b-col class="col-6">
                                <b-form-checkbox
                                  id="checkbox-sunday-business-hours"
                                  v-model="storeHours.Sunday.business_hours_status"
                                  name="checkbox-sunday-business-hours"
                                  :value="1"
                                  :unchecked-value="0"
                                >
                                  <span style="margin-left: -20px">{{ $t('Business Hours') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Sunday.business_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Sunday.business_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Open_Store')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Sunday.business_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Close_Store')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Closed') }}</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="col-2 text-center">
                            <b-form-checkbox
                              id="checkbox-sunday-delivery-hours"
                              v-model="storeHours.Sunday.delivery_hours_status"
                              name="checkbox-sunday-delivery-hours"
                              :value="1"
                              :unchecked-value="0"
                            >
                              <span style="margin-left: -20px">{{ $t('Delivery Hours') }}</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col class="col-3">
                            <b-row v-if="storeHours.Sunday.delivery_hours_status">
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Start') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Sunday.delivery_hours_start"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('Start')"
                                ></flat-pickr>
                              </b-col>
                              <b-col class="col-2 text-right" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('End') }}</b-col>
                              <b-col class="col-4">
                                <flat-pickr
                                  v-model="storeHours.Sunday.delivery_hours_end"
                                  :config="pickr_config2"
                                  id="fp"
                                  class="form-control input_height_1_5"
                                  :placeholder="$t('End')"
                                ></flat-pickr>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col class="col-12 text-center" style="font-size: 0.95rem; padding: 0px !important"> {{ $t('Not Delivering') }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </el-tab-pane>
              <el-tab-pane name="delivery">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-delivery" class="dot" :style="dot_tab_delivery_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ $t('Delivery & Tips') }}</span>
                  </span>
                </template>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Delivery Mode") + ':'  }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please choose the mode of delivery.")'
                    >
                      <el-radio-group v-model="cloud_store.DeliveryMode">
                        <el-radio label="third party" border>{{ $t('Third Party') }}</el-radio>
                        <el-radio label="self" border>{{ $t('Self') }}</el-radio>
                      </el-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="cloud_store.DeliveryMode == 'self'">
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Minimum Delivery Price" ) + ':' }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please enter the minimum order price to qualify for delivery. (Only applicable in \"Self\" delivery mode)")'
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text id="minPrice_b_input_prepend"><strong>$</strong></b-input-group-text>
                        </template>
                        <b-form-input
                          id="minPrice_b_input"
                          v-model="cloud_store.DeliveryMinPrice"
                          :placeholder="$t('Minimum Delivery Price')"
                        ></b-form-input>                        
                      </b-input-group>
                      <span id="minPrice_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="cloud_store.DeliveryMode == 'self'">
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Fixed Delivery Fee") + ':' }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                      :description='$t("Please enter the fixed delivery fee. (Only applicable in \"Self\" delivery mode)")'
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text id="deliveryFee_b_input_prepend"><strong>$</strong></b-input-group-text>
                        </template>
                        <b-form-input
                          id="deliveryFee_b_input"
                          v-model="cloud_store.DeliveryFee"
                          :placeholder="$t('Fixed Delivery Fee')"
                        ></b-form-input>
                      </b-input-group>
                      <span id="deliveryFee_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="text-right">
                    <label class="mt-2 mr-sm-2">{{ $t("Tips Percentage" ) + ':' }}</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-input-group>
                        <el-form
                          ref="formTips"
                          v-model="cloud_store.Tips"
                          label-width="80px"
                        >
                          <el-form-item
                            v-for="(tip, index) in cloud_store.Tips"
                            :key="tip.key"
                            :label="'Tips ' + [[index+1]]"
                          >
                            <b-row>
                              <b-col cols="6">
                                <b-input-group>
                                  <template #append>
                                    <b-input-group-text><strong>%</strong></b-input-group-text>
                                  </template>
                                  <b-form-input
                                    v-model="tip.value"
                                    :placeholder="$t('Tips')"
                                    required
                                  ></b-form-input>
                                </b-input-group>
                              </b-col>
                              <b-col cols="1">
                                <el-button size="small" type="danger" @click.prevent="handleRemoveTips(tip)">{{ $t('Delete') }}</el-button>
                              </b-col>
                            </b-row>                            
                          </el-form-item>
                          <el-form-item>
                              <el-button type="success" @click="handleAddTips" >{{ $t('New') }}</el-button>
                          </el-form-item>
                        </el-form>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </el-tab-pane>
              <el-tab-pane name="others">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="margin: 0px 20px"><span id="dot-tab-others" class="dot" :style="dot_tab_others_visible ? 'opacity: 1' : 'opacity: 0'"/>{{ $t('Others') }}</span>
                  </span>
                </template>
                <b-row class="mt-3">
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div 
                          class="form-control-label" 
                          :title=" $t('Auto-Confirm will automatically confirm your online orders for you. You will not be able to manually confirm each order.') "
                          style="padding-top: 3px">
                          {{ $t('Auto-Confirm') }}
                        </div>
                      </b-col>
                      <b-col class="col-6" style="padding-top: 3px">
                        <b-form-checkbox v-model="cloud_store.AutoConfirm_status" value="1" unchecked-value="0" name="check-button" switch></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div
                          class="form-control-label"
                          :title=" $t('When customers choose to pay in store, SMS-verification requires customers to verify themselves through SMS. If customers are paying online, this setting is not applicable.') "
                          style="padding-top: 3px">
                          {{ $t('SMS-verification') }}
                        </div>
                      </b-col>
                      <b-col class="col-6" style="padding-top: 3px">
                        <b-form-checkbox v-model="cloud_store.SMS_verification" value="1" unchecked-value="0" name="check-button" switch></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div 
                          class="form-control-label"
                          :title=" $t('The preparation time for each online order. This applies to all online orders, regardless of the variety and quantity of products in the order.')"
                          style="padding-top: 3px">
                          {{ $t('Preparation') }}
                        </div>
                      </b-col>
                      <b-col class="col-6">
                        <el-select id="prepTime_el_select" v-model="cloud_store.PrepTime_min" placeholder="Select" size="small">
                          <el-option
                            v-for="item in times"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                          </el-option>
                        </el-select>
                        <span id="prepTime_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div
                          class="form-control-label"
                          :title=" $t('') "
                          style="padding-top: 3px">
                          {{ $t('Interval') }}
                        </div>
                      </b-col>
                      <b-col class="col-6">
                        <el-select id="interval_el_select" v-model="cloud_store.Interval_min" placeholder="Select" size="small">
                          <el-option
                            v-for="item in times"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                          </el-option>
                        </el-select>
                        <span id="interval_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div
                          class="form-control-label"
                          :title=" $t('') "
                          style="padding-top: 3px">
                          {{ $t('Auto-Complete') }}
                        </div>
                      </b-col>
                      <b-col class="col-6">
                        <el-select id="autoComplete_el_select" v-model="cloud_store.AutoComplete_min" placeholder="Select" size="small">
                          <el-option
                            v-for="item in times"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                          </el-option>
                        </el-select>
                        <span id="autoComplete_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col class="col-3">
                    <b-row>
                      <b-col class="col-6 font-weight-bold text-sm-right">
                        <div 
                          class="form-control-label"
                          :title=" $t('Auto-Cancel will automatically cancel the order if the customers do not confirm their orders within a period of time.') "
                          style="padding-top: 3px">
                          {{ $t('Auto-Cancel') }}
                        </div>
                      </b-col>
                      <b-col class="col-6">
                        <el-select id="autoCancel_el_select" v-model="cloud_store.AutoCancel_min" placeholder="Select" size="small">
                          <el-option
                            v-for="item in times"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                          </el-option>
                        </el-select>
                        <span id="autoCancel_errMsg" style="display: none; font-size: 0.8rem; color: red;"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                
              </el-tab-pane>
            </el-tabs>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="onReset">{{ $t('Cancel') }}</el-button>
              <el-button type="primary" @click="onSubmit">{{ $t('Confirm') }}</el-button>
            </span>
          </el-dialog>
          <el-dialog
            class="setAll"
            :title='$t("Set All")'
            :visible.sync="setAllVisible"
            width="40%"
            center>
            <b-row>
              <b-col class="col-12">
                <b-row>
                  <b-col class="col-3 text-center" style="font-size: 1.2rem;">{{ $t('Business Hours') }}</b-col>
                  <b-col class="col-2 text-right" style="font-size: 1.2rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="all_business_hours_start"
                      :config="pickr_config2"
                      id="fp"
                      class="form-control input_height_2_0"
                      :placeholder="$t('Open_Store')"
                    ></flat-pickr>
                  </b-col>
                  <b-col class="col-2 text-right" style="font-size: 1.2rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="all_business_hours_end"
                      :config="pickr_config2"
                      id="fp"
                      class="form-control input_height_2_0"
                      :placeholder="$t('Close_Store')"
                    ></flat-pickr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row><br>
            <b-row>
              <b-col class="col-12">
                <b-row>
                  <b-col class="col-3 text-center" style="font-size: 1.2rem;">{{ $t('Delivery Hours') }}</b-col>
                  <b-col class="col-2 text-right" style="font-size: 1.2rem; padding: 0px !important"> {{ $t('Open_Store') }}</b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="all_delivery_hours_start"
                      :config="pickr_config2"
                      id="fp"
                      class="form-control input_height_2_0"
                      :placeholder="$t('Open_Store')"
                    ></flat-pickr>
                  </b-col>
                  <b-col class="col-2 text-right" style="font-size: 1.2rem; padding: 0px !important"> {{ $t('Close_Store') }}</b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="all_delivery_hours_end"
                      :config="pickr_config2"
                      id="fp"
                      class="form-control input_height_2_0"
                      :placeholder="$t('Close_Store')"
                    ></flat-pickr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <span slot="footer" class="dialog-footer">
              <el-button class="mr-2" @click="setAllVisible = false">{{ $t("Cancel") }}</el-button>
              <el-button class="ml-2" type="primary" @click="onSetAll">{{ $t("Apply") }}</el-button>
            </span>
          </el-dialog>
        </b-card>
      </div>
    </b-container>
    <el-dialog
      :title='$t("Synchronization")'
      :visible.sync="synchronization_warning"
      width="50%"
      center>
      <span>{{ $t("You are about to synchronize menus to Opencart. This process will take awhile.") }}</span><br>
      <span>{{ $t("Once the process started, it can no longer be paused or stopped.") }}</span><br>
      <span>{{ $t("Refreshing or closing the browser will cause errors.") }}</span><br>
      <span>{{ $t("Please wait for the process to complete patiently.") }}</span><br><br>
      <span>{{ $t("Are you sure you would like to start the synchronization now?") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="mr-2" type="danger" @click="synchronization_warning = false">{{ $t("No") }}</el-button>
        <el-button class="ml-2" type="primary" @click="syncCloudStore()">{{ $t("Yes") }}</el-button>
      </span>
    </el-dialog>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
    <vue-element-loading
      :active="showSyncing"
      spinner="ring"
      is-full-screen
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, 0)"
      size="150"
    />
  </div>
</template>
<script>
import 
{ 
  Table,
  TableColumn,
  Button,
  Tag,
  Dialog,
  MessageBox,
  ButtonGroup,
  Progress,
  Tabs,
  TabPane,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Form,
  FormItem,
} from "element-ui";
import { getStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Dialog.name]: Dialog,
    [MessageBox.name]: MessageBox,
    [ButtonGroup.name]: ButtonGroup,
    [Progress.name]: Progress,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    VueElementLoading,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      token: '',
      user_id: 0,
      cloud_stores: [],
      cloud_store: [],
      menus: [],
      menu_options: [],
      tables: [],
      table_options: [],
      languages: [],
      lang_options: [],
      times: [
        {value: 15, text:"15 min"},
        {value: 30, text:"30 min"},
        {value: 45, text:"45 min"},
        {value: 60, text:"60 min"},
      ],
      pickr_config2: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },

      showloading: false,
      showSyncing: false,
      dialogVisible: false,
      setAllVisible: false,
      synchronization_warning: false,
      synchronization_in_progress: false,
      showErrorMessage: false,
      dot_tab_api_visible: false,
      dot_tab_menu_visible: false,
      dot_tab_table_visible: false,
      dot_tab_language_visible: false,
      dot_tab_delivery_visible: false,
      dot_tab_others_visible: false,

      disMessage: "",
      errorMessage: "",
      countdown: 0,
      sync_percentage: 0,
      timeout: null,
      syncMessage: null,
      checkpoint_total: null,
      checkpoint_current: null,
      activeTab: "api",

      all_business_hours_start: null,
      all_business_hours_end: null,
      all_delivery_hours_start: null,
      all_delivery_hours_end: null,
      storeHours: {
        Monday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Tuesday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Wednesday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Thursday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Friday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Saturday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
        Sunday: {
          business_hours_status: null,
          business_hours_start: null,
          business_hours_end: null,
          delivery_hours_status: null,
          delivery_hours_start: null,
          delivery_hours_end: null,
        },
      },
    };
  },
  watch: {

    // Monday watcher
    'storeHours.Monday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Monday.business_hours_start = null;
        this.storeHours.Monday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Monday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Monday.delivery_hours_start = null;
        this.storeHours.Monday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Tuesday watcher
    'storeHours.Tuesday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Tuesday.business_hours_start = null;
        this.storeHours.Tuesday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Tuesday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Tuesday.delivery_hours_start = null;
        this.storeHours.Tuesday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Wednesday watcher
    'storeHours.Wednesday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Wednesday.business_hours_start = null;
        this.storeHours.Wednesday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Wednesday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Wednesday.delivery_hours_start = null;
        this.storeHours.Wednesday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Thursday watcher
    'storeHours.Thursday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Thursday.business_hours_start = null;
        this.storeHours.Thursday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Thursday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Thursday.delivery_hours_start = null;
        this.storeHours.Thursday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Friday watcher
    'storeHours.Friday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Friday.business_hours_start = null;
        this.storeHours.Friday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Friday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Friday.delivery_hours_start = null;
        this.storeHours.Friday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Saturday watcher
    'storeHours.Saturday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Saturday.business_hours_start = null;
        this.storeHours.Saturday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Saturday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Saturday.delivery_hours_start = null;
        this.storeHours.Saturday.delivery_hours_end = null;
      }
    },
    deep: true,

    // Sunday watcher
    'storeHours.Sunday.business_hours_status': function(val){
      if(!val){
        this.storeHours.Sunday.business_hours_start = null;
        this.storeHours.Sunday.business_hours_end = null;
      }
    },
    deep: true,
    'storeHours.Sunday.delivery_hours_status': function(val){
      if(!val){
        this.storeHours.Sunday.delivery_hours_start = null;
        this.storeHours.Sunday.delivery_hours_end = null;
      }
    },
    deep: true,
  },
  methods: {
    closeAlert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.disMessage = "";
      }
    },
    updateProgress(){
      this.timeout = setInterval(()=>{
        this.sync_percentage = parseInt(this.checkpoint_current/this.checkpoint_total*100);
      },10);
    },
    setProgressCheckpoint(data){
      return data.obj.prodsWithImage.length + 1;  // Plus 1 checkpoint for sync menus
    },
    syncError(msg){
      this.showSyncing = false;
      this.errorMessage = msg;
      this.showErrorMessage = true;
      this.synchronization_in_progress = false;
      clearTimeout(this.timeout);
    },  
    syncComplete(){
      this.showSyncing = false;
      this.disMessage = "Synchronization Completed!";
      this.countdown = 3;
      clearTimeout(this.timeout);
      this.sync_percentage = 100;
      this.syncMessage = "";
      setTimeout(()=>{
        this.synchronization_in_progress = false;
      },3000);
    },
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    },
    handleEditStore(index, store){
      this.dialogVisible = true;
      this.activeTab = "api";
      this.cloud_store = JSON.parse(JSON.stringify(store));
      this.convertStoreOthers();
      this.setTableOptions(this.cloud_store);
      this.convertStoreHours();
      this.convertTips();
      this.resetValidate();
    },
    convertStoreOthers(){
      this.cloud_store.AutoCancel_min = parseInt(this.cloud_store.AutoCancel_min);
      this.cloud_store.AutoComplete_min = parseInt(this.cloud_store.AutoComplete_min);
      this.cloud_store.Interval_min = parseInt(this.cloud_store.Interval_min);
      this.cloud_store.PrepTime_min = parseInt(this.cloud_store.PrepTime_min);
    },
    handleSyncWarning(index, store){
      this.cloud_store = JSON.parse(JSON.stringify(store));
      this.setTableOptions(this.cloud_store);
      this.convertStoreHours();
      this.convertTips();
      this.resetValidate();
      this.validateSync();
    },
    handleSwitchTab(tab, event){
      // console.log(tab,event);
    },
    handleClickSetAll(){
      this.all_business_hours_start = null,
      this.all_business_hours_end = null,
      this.all_delivery_hours_start = null,
      this.all_delivery_hours_end = null,
      this.setAllVisible = true;
    },
    handleRemoveTips(item){
      const index = this.cloud_store.Tips.indexOf(item)
      if (index !== -1) {
        this.cloud_store.Tips.splice(index, 1)
      }
    },
    handleAddTips() {
      this.cloud_store.Tips.push({
        key: Date.now(),
        value: '',
      })
    },
    onSubmit(event) {
      event.preventDefault()
      this.resetValidate();
      this.validateForm();
    },
    onReset(event) {
      event.preventDefault()
      this.dialogVisible = false;
    },
    onSetAll(event) {
      event.preventDefault()
      this.setAll();
      this.setAllVisible = false;
    },
    resetValidate(){
      this.dialogVisible = true;
      this.$nextTick(function() {
        document.getElementById("lang1_el_select").style.borderColor = "revert-layer";
        document.getElementById("lang2_el_select").style.borderColor = "revert-layer";
        document.getElementById("prepTime_el_select").style.borderColor = "revert-layer";
        document.getElementById("interval_el_select").style.borderColor = "revert-layer";
        document.getElementById("autoComplete_el_select").style.borderColor = "revert-layer";
        document.getElementById("autoCancel_el_select").style.borderColor = "revert-layer";
        if(this.cloud_store.DeliveryMode == 'self'){
          document.getElementById("deliveryFee_b_input").style.borderColor = "revert-layer";
          document.getElementById("deliveryFee_b_input_prepend").style.borderColor = "revert-layer";
          document.getElementById("minPrice_b_input").style.borderColor = "revert-layer";
          document.getElementById("minPrice_b_input_prepend").style.borderColor = "revert-layer";
        }
        document.getElementById("tableDelivery_el_select").style.borderColor = "revert-layer";
        document.getElementById("tablePickUp_el_select").style.borderColor = "revert-layer";
        document.getElementById("menu_el_select").style.borderColor = "revert-layer";
        document.getElementById("url_b_input").style.borderColor = "revert-layer";
        document.getElementById("username_b_input").style.borderColor = "revert-layer";
        document.getElementById("key_b_input").style.borderColor = "revert-layer";
        document.getElementById("lang1_errMsg").style.display = "none";
        document.getElementById("lang2_errMsg").style.display = "none";
        document.getElementById("prepTime_errMsg").style.display = "none";
        document.getElementById("interval_errMsg").style.display = "none";
        document.getElementById("autoComplete_errMsg").style.display = "none";
        document.getElementById("autoCancel_errMsg").style.display = "none";
        if(this.cloud_store.DeliveryMode == 'self'){
          document.getElementById("deliveryFee_errMsg").style.display = "none";
          document.getElementById("minPrice_errMsg").style.display = "none";
        }
        document.getElementById("tableDelivery_errMsg").style.display = "none";
        document.getElementById("tablePickUp_errMsg").style.display = "none";
        document.getElementById("menu_errMsg").style.display = "none";
        document.getElementById("url_errMsg").style.display = "none";
        document.getElementById("username_errMsg").style.display = "none";
        document.getElementById("key_errMsg").style.display = "none";
        
        this.dot_tab_api_visible = false;
        this.dot_tab_menu_visible = false;
        this.dot_tab_table_visible = false;
        this.dot_tab_language_visible = false;
        this.dot_tab_delivery_visible = false;
        this.dot_tab_others_visible = false;
      })
    },
    validateForm(){
      if(this.cloud_store.Lang1 && this.cloud_store.Lang2){
        if(this.cloud_store.Lang1 == this.cloud_store.Lang2){
          this.activeTab = "language";
          this.$nextTick(function() {
            document.getElementById("lang1_el_select").style.borderColor = "red";
            document.getElementById("lang2_el_select").style.borderColor = "red";
            document.getElementById("lang1_errMsg").style.display = "unset";
            document.getElementById("lang1_errMsg").innerHTML = "Language must be different in Name1 and Name2";
            document.getElementById("lang2_errMsg").style.display = "unset";
            document.getElementById("lang2_errMsg").innerHTML = "Language must be different in Name1 and Name2";
            this.dot_tab_language_visible = true;
          })
        } else {
          this.submitCloudStore();
        }
      } else {
        this.submitCloudStore();
      }
    },
    validateSync(){
      var validated = true;
      
      // OTHERS
      if (!this.cloud_store.PrepTime_min){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "others";
        this.$nextTick(function() {
          document.getElementById("prepTime_el_select").style.borderColor = "red";
          document.getElementById("prepTime_errMsg").style.display = "unset";
          document.getElementById("prepTime_errMsg").innerHTML = "Required"
          this.dot_tab_others_visible = true;
        })
      }
      if (!this.cloud_store.Interval_min){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "others";
        this.$nextTick(function() {
          document.getElementById("interval_el_select").style.borderColor = "red";
          document.getElementById("interval_errMsg").style.display = "unset";
          document.getElementById("interval_errMsg").innerHTML = "Required"
          this.dot_tab_others_visible = true;
        })
      }
      if (!this.cloud_store.AutoComplete_min){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "others";
        this.$nextTick(function() {
          document.getElementById("autoComplete_el_select").style.borderColor = "red";
          document.getElementById("autoComplete_errMsg").style.display = "unset";
          document.getElementById("autoComplete_errMsg").innerHTML = "Required"
          this.dot_tab_others_visible = true;
        })
      }
      if (!this.cloud_store.AutoCancel_min){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "others";
        this.$nextTick(function() {
          document.getElementById("autoCancel_el_select").style.borderColor = "red";
          document.getElementById("autoCancel_errMsg").style.display = "unset";
          document.getElementById("autoCancel_errMsg").innerHTML = "Required"
          this.dot_tab_others_visible = true;
        })
      }

      // DELIVERY
      if(this.cloud_store.DeliveryMode == 'self'){
        if (!this.cloud_store.DeliveryFee){
          validated = false;
          this.dialogVisible = true;
          this.activeTab = "delivery";
          this.$nextTick(function() {
            document.getElementById("deliveryFee_b_input").style.borderColor = "red";
            document.getElementById("deliveryFee_b_input_prepend").style.borderColor = "red";
            document.getElementById("deliveryFee_errMsg").style.display = "unset";
            document.getElementById("deliveryFee_errMsg").innerHTML = "Required"
            this.dot_tab_delivery_visible = true;
          })
        }
        if (!this.cloud_store.DeliveryMinPrice){
          validated = false;
          this.dialogVisible = true;
          this.activeTab = "delivery";
          this.$nextTick(function() {
            document.getElementById("minPrice_b_input").style.borderColor = "red";
            document.getElementById("minPrice_b_input_prepend").style.borderColor = "red";
            document.getElementById("minPrice_errMsg").style.display = "unset";
            document.getElementById("minPrice_errMsg").innerHTML = "Required"
            this.dot_tab_delivery_visible = true;
          })
        }
      }
      
      // LANG
      if (!this.cloud_store.Lang1){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "language";
        this.$nextTick(function() {
          document.getElementById("lang1_el_select").style.borderColor = "red";
          document.getElementById("lang1_errMsg").style.display = "unset";
          document.getElementById("lang1_errMsg").innerHTML = "Required"
          this.dot_tab_language_visible = true;
        })
      }
      if (!this.cloud_store.Lang2){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "language";
        this.$nextTick(function() {
          document.getElementById("lang2_el_select").style.borderColor = "red";
          document.getElementById("lang2_errMsg").style.display = "unset";
          document.getElementById("lang2_errMsg").innerHTML = "Required"
          this.dot_tab_language_visible = true;
        })
      }

      // TABLE
      if (!this.cloud_store.TableDelivery){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "table";
        this.$nextTick(function() {
          document.getElementById("tableDelivery_el_select").style.borderColor = "red";
          document.getElementById("tableDelivery_errMsg").style.display = "unset";
          document.getElementById("tableDelivery_errMsg").innerHTML = "Required"
          this.dot_tab_table_visible = true;
        })
      }
      if (!this.cloud_store.TablePickUp){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "table";
        this.$nextTick(function() {
          document.getElementById("tablePickUp_el_select").style.borderColor = "red";
          document.getElementById("tablePickUp_errMsg").style.display = "unset";
          document.getElementById("tablePickUp_errMsg").innerHTML = "Required"
          this.dot_tab_table_visible = true;
        })
      }

      // MENU
      if (!this.cloud_store.MenuIDn){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "menu";
        this.$nextTick(function() {
          document.getElementById("menu_el_select").style.borderColor = "red";
          document.getElementById("menu_errMsg").style.display = "unset";
          document.getElementById("menu_errMsg").innerHTML = "Required"
          this.dot_tab_menu_visible = true;
        })
      }

      // API
      if (!this.cloud_store.URL){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "api";
        this.$nextTick(function() {
          document.getElementById("url_b_input").style.borderColor = "red";
          document.getElementById("url_errMsg").style.display = "unset";
          document.getElementById("url_errMsg").innerHTML = "Required"
          this.dot_tab_api_visible = true;
        })
      }
      if (!this.cloud_store.Username){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "api";
        this.$nextTick(function() {
          document.getElementById("username_b_input").style.borderColor = "red";
          document.getElementById("username_errMsg").style.display = "unset";
          document.getElementById("username_errMsg").innerHTML = "Required"
          this.dot_tab_api_visible = true;
        })
      }
      if (!this.cloud_store.Key){
        validated = false;
        this.dialogVisible = true;
        this.activeTab = "api";
        this.$nextTick(function() {
          document.getElementById("key_b_input").style.borderColor = "red";
          document.getElementById("key_errMsg").style.display = "unset";
          document.getElementById("key_errMsg").innerHTML = "Required"
          this.dot_tab_api_visible = true;
        })
      }
      
      if(validated){
        this.dialogVisible = false;
        this.synchronization_warning = true;
      }
    },
    getMenuNameByIDn(IDn){
      for(var i=0; i<this.menus.length; i++){
        if(this.menus[i].IDn == IDn){
          return this.menus[i].Name1;
        }
      }
      return IDn;
    },
    setLanguageOptions(languages){
      if(this.lang_options.length !== 0) return;
      for(var i=0; i<languages.length; i++){
        this.lang_options.push({ value: languages[i].IDn, text: languages[i].Name });
      }
    },
    setMenuOptions(menus){
      if(this.menu_options.length !== 0) return;
      for(var i=0; i<menus.length; i++){
        this.menu_options.push({ value: menus[i].IDn, text: menus[i].Name1 });
      }
    },
    setAll(){
      this.storeHours.Monday.business_hours_status = 1;
      this.storeHours.Tuesday.business_hours_status = 1;
      this.storeHours.Wednesday.business_hours_status = 1;
      this.storeHours.Thursday.business_hours_status = 1;
      this.storeHours.Friday.business_hours_status = 1;
      this.storeHours.Saturday.business_hours_status = 1;
      this.storeHours.Sunday.business_hours_status = 1;

      this.storeHours.Monday.delivery_hours_status = 1;
      this.storeHours.Tuesday.delivery_hours_status = 1;
      this.storeHours.Wednesday.delivery_hours_status = 1;
      this.storeHours.Thursday.delivery_hours_status = 1;
      this.storeHours.Friday.delivery_hours_status = 1;
      this.storeHours.Saturday.delivery_hours_status = 1;
      this.storeHours.Sunday.delivery_hours_status = 1;

      this.storeHours.Monday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Tuesday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Wednesday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Thursday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Friday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Saturday.business_hours_start = this.all_business_hours_start;
      this.storeHours.Sunday.business_hours_start = this.all_business_hours_start;

      this.storeHours.Monday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Tuesday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Wednesday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Thursday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Friday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Saturday.business_hours_end = this.all_business_hours_end;
      this.storeHours.Sunday.business_hours_end = this.all_business_hours_end;

      this.storeHours.Monday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Tuesday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Wednesday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Thursday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Friday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Saturday.delivery_hours_start = this.all_delivery_hours_start;
      this.storeHours.Sunday.delivery_hours_start = this.all_delivery_hours_start;

      this.storeHours.Monday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Tuesday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Wednesday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Thursday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Friday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Saturday.delivery_hours_end = this.all_delivery_hours_end;
      this.storeHours.Sunday.delivery_hours_end = this.all_delivery_hours_end;
    },
    setTableOptions(store){
      // filter by store ID
      var location_tables = JSON.parse(JSON.stringify(this.tables));
      for(var i=location_tables.length-1; i>=0; i--){
        if(location_tables[i].LocationID != store.LocationID){
          location_tables.splice(i, 1);
        }
      }
      this.table_options = [];
      for(var i=0; i<location_tables.length; i++){
        this.table_options.push({ value: location_tables[i].IDn, text: location_tables[i].Name });
      }
    },
    setStoreHours(){
      this.cloud_store.Monday = '[' + '"' + this.storeHours.Monday.business_hours_start + '",' + '"' + this.storeHours.Monday.business_hours_end + '",'+ '"' + this.storeHours.Monday.delivery_hours_start + '",' + '"' + this.storeHours.Monday.delivery_hours_end + '"' + ']';
      this.cloud_store.Tuesday = '[' + '"' + this.storeHours.Tuesday.business_hours_start + '",' + '"' + this.storeHours.Tuesday.business_hours_end + '",'+ '"' + this.storeHours.Tuesday.delivery_hours_start + '",' + '"' + this.storeHours.Tuesday.delivery_hours_end + '"' + ']';
      this.cloud_store.Wednesday = '[' + '"' + this.storeHours.Wednesday.business_hours_start + '",' + '"' + this.storeHours.Wednesday.business_hours_end + '",'+ '"' + this.storeHours.Wednesday.delivery_hours_start + '",' + '"' + this.storeHours.Wednesday.delivery_hours_end + '"' + ']';
      this.cloud_store.Thursday = '[' + '"' + this.storeHours.Thursday.business_hours_start + '",' + '"' + this.storeHours.Thursday.business_hours_end + '",'+ '"' + this.storeHours.Thursday.delivery_hours_start + '",' + '"' + this.storeHours.Thursday.delivery_hours_end + '"' + ']';
      this.cloud_store.Friday = '[' + '"' + this.storeHours.Friday.business_hours_start + '",' + '"' + this.storeHours.Friday.business_hours_end + '",'+ '"' + this.storeHours.Friday.delivery_hours_start + '",' + '"' + this.storeHours.Friday.delivery_hours_end + '"' + ']';
      this.cloud_store.Saturday = '[' + '"' + this.storeHours.Saturday.business_hours_start + '",' + '"' + this.storeHours.Saturday.business_hours_end + '",'+ '"' + this.storeHours.Saturday.delivery_hours_start + '",' + '"' + this.storeHours.Saturday.delivery_hours_end + '"' + ']';
      this.cloud_store.Sunday = '[' + '"' + this.storeHours.Sunday.business_hours_start + '",' + '"' + this.storeHours.Sunday.business_hours_end + '",'+ '"' + this.storeHours.Sunday.delivery_hours_start + '",' + '"' + this.storeHours.Sunday.delivery_hours_end + '"' + ']';
    },
    setTips(){
      var temp = [];
      if(!Array.isArray(this.cloud_store.Tips)) {
        return;
      }
      for(var i=0; i<this.cloud_store.Tips.length; i++){
        if(this.cloud_store.Tips[i].value){
          temp.push(parseInt(this.cloud_store.Tips[i].value));
        }
      }
      this.cloud_store.Tips = JSON.stringify(temp);
    },
    convertHours(dayOfWeek, hours){
      hours[0] == 'null' ? hours[0] = null : '';
      hours[1] == "null" ? hours[1] = null : '';
      hours[2] == "null" ? hours[2] = null : '';
      hours[3] == "null" ? hours[3] = null : '';
      this.storeHours[dayOfWeek].business_hours_start = hours [0];
      this.storeHours[dayOfWeek].business_hours_end = hours[1];
      this.storeHours[dayOfWeek].delivery_hours_start = hours[2];
      this.storeHours[dayOfWeek].delivery_hours_end = hours[3];
      if(this.storeHours[dayOfWeek].business_hours_start || this.storeHours[dayOfWeek].business_hours_end){
        this.storeHours[dayOfWeek].business_hours_status = 1;
      } else {
        this.storeHours[dayOfWeek].business_hours_status = 0;
      }
      if(this.storeHours[dayOfWeek].delivery_hours_start || this.storeHours[dayOfWeek].delivery_hours_end){
        this.storeHours[dayOfWeek].delivery_hours_status = 1;
      } else {
        this.storeHours[dayOfWeek].delivery_hours_status = 0;
      }
    },
    convertStoreHours(){
      this.convertHours("Monday", JSON.parse(this.cloud_store.Monday));
      this.convertHours("Tuesday", JSON.parse(this.cloud_store.Tuesday));
      this.convertHours("Wednesday", JSON.parse(this.cloud_store.Wednesday));
      this.convertHours("Thursday", JSON.parse(this.cloud_store.Thursday));
      this.convertHours("Friday", JSON.parse(this.cloud_store.Friday));
      this.convertHours("Saturday", JSON.parse(this.cloud_store.Saturday));
      this.convertHours("Sunday", JSON.parse(this.cloud_store.Sunday));
    },
    convertTips(){
      if(!this.isJsonString(this.cloud_store.Tips)){
        this.cloud_store.Tips = [];
        return;
      }
      this.cloud_store.Tips = JSON.parse(this.cloud_store.Tips);
      for(var i=0; i<this.cloud_store.Tips.length; i++){
        this.cloud_store.Tips[i] = {
          key: Math.random(),
          value: this.cloud_store.Tips[i],
        }
      }
    },
    async syncCloudStore(){
      this.synchronization_warning = false;
      this.synchronization_in_progress = true;
      this.sync_percentage = 0;
      this.syncMessage = "Synchronizing menus...";
      this.showSyncing = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("locationID", this.cloud_store['LocationID']);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/cloud_store/sync",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if(rt.data.error){
                that.syncError(rt.data.error);
              } else {
                that.checkpoint_total = that.setProgressCheckpoint(rt.data);
                that.checkpoint_current = 1;    // Already completed first checkpoint - sync menus
                that.updateProgress();
                that.syncImages(rt.data.token, rt.data.locationID, rt.data.obj.prodsWithImage);
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async syncImages(opencart_token, locationID, obj){
      if(!Array.isArray(obj)) return;
      this.syncImage(opencart_token, locationID, obj, obj.length);
    },
    async syncImage(opencart_token, locationID, obj, length){
      if(length == 0){
        // stop calling itself
        this.syncComplete();
        return;
      }
      this.syncMessage = "Synchronizing " + obj[length-1]['imagefile'];
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("opencart_token", opencart_token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("image", obj[length-1]['imagefile']);
      bodyFormData.append("idn", obj[length-1]['IDn']);
      bodyFormData.append("locationID", locationID);
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/cloud_store/syncImageToOpencart",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.checkpoint_current++;
              that.syncImage(opencart_token, locationID, obj, length-1);  // Recursive call
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async submitCloudStore() {
      this.dialogVisible = false;
      this.showloading = true;
      this.setStoreHours();
      this.setTips();
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for(const property in this.cloud_store){
        if(this.cloud_store[property]){     // detect all falsey values
          bodyFormData.append(property, this.cloud_store[property]);
        }
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/cloud_store/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.getCloudStores();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async getCloudStores() {
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/cloud_store",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cloud_stores = rt.data.cloud_stores;
              that.menus = rt.data.menus;
              that.tables = rt.data.tables;
              that.languages = rt.data.languages;
              that.setMenuOptions(that.menus);
              that.setLanguageOptions(that.languages);
              that.showloading = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.getCloudStores();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding-top: 80px;
}
/deep/ .my-header {
  background: #00e1ff !important;
  color: rgb(17, 16, 16) !important;
}
/deep/ .el-table td, .el-table th {
  text-align: center;
  font-size: 16px !important;
}
/deep/ .el-table th {
  text-align: center !important;
  font-size: 16px !important;
}

/deep/ .el-tag {
  font-size: 18px !important;
  background-color: rgba(103,194,58,.1) !important;
  border-color: rgba(103,194,58,.2) !important;
  color: #67c23a !important;
  padding: 5px 15px !important;
  line-height: 20px !important;
}
/deep/ .input_height_1_5 {
  height: 1.5rem;
  padding: 0 0.75rem;
}
/deep/ .input_height_2_0 {
  height: 2rem;
  padding: 0 0.75rem;
}
/deep/ #fp {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: #ced4da !important;
  background-color: white !important;
}
/deep/ .el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .input-group-prepend {
  width: 50px !important;
}
/deep/ .input-group-append {
  width: 20px !important;
}

/deep/ .setAll .el-dialog__title {
  font-size: 1.5rem !important;
}
/deep/ .dot {
  height: 7px;
  width: 7px;
  background-color: #ff0000;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
  display: inline-block;
}
</style>
