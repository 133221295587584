<template>
  <div :style="tablediv" @dragstart="dragstart($event)" @dragend="dragend($event)">
    <span :style="tablespan" >
      {{ tablename }}
    </span>
    <i @click="removetable($event)" class="el-icon-circle-close table-remove2" style="color: #F56C6C"></i>
  </div>
</template>
<script>
  export default {
    name: 'Ktable',
    props: {
      name: {
        type: String,
        required: true,
        default: '',
      },
      shape: {
        type: String,
        required: false,
        default: 's', // s: square, h: h>w, w: w>h, r: round
      },
      small: {
        type: Boolean,
        required: false,
        default: false,
      },
      width: {
        type: Number,
        required: false,
        default: 60,
      },
      height: {
        type: Number,
        required: false,
        default: 60,
      },
      color: {
        type: String,
        required: false,
        default: '#c0c0c0',
      },
      fontsize: {
        type: Number,
        required: false,
        default: 20,
      },
    },
    components: {
    },
    data() {
      return {
        tablename: '',
        disableImg: false,
        tablediv: {
          position: "absolute",
          backgroundColor: "#c0c0c0",
          textAlign: "center",
          fontSize: "20px",
          paddingTop: "20px",
          lineHeight: "20px",
          width: "60px",
          height: "60px",
          border: "0px",
          borderRadius: "5px",  // r "50%"
          fontWeight: 400,
        },
        tablespan: {
          display: "inline-block",
          marginTop: "14px",
        },
      };
    },
    methods: {
      dragstart(evt) {
        this.$emit('dragstart_p', evt);
      },
      dragend(evt) {
        this.$emit('dragend_p', evt);
      },
      removetable(evt) {
        evt.preventDefault();
        this.$emit('removetable', evt);
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          ;
        }
      },
    },
    mounted() {
      this.tablename = this.name;

      if (this.small) {
        var fz = this.fontsize / 2;
        this.tablediv.fontSize = "10px";
        this.tablediv.width = (parseInt(this.width) / 2) + "px";
        this.tablediv.height = (parseInt(this.height) / 2) + "px";
        this.tablespan.marginTop = parseInt((parseInt(this.height) / 2 - fz - 4) / 2) + "px";
      } else {
        this.tablediv.width = parseInt(this.width) + "px";
        this.tablediv.height = parseInt(this.height) + "px";
        // Lisa modify for change the fontsize and padding Top ---start---
        // this.tablespan.marginTop = parseInt((this.height - this.fontsize - 8) / 2) + "px";
        this.tablespan.marginTop = "0px";
        this.tablediv.lineHeight = this.tablediv.fontSize;
        var nn = this.tablediv.fontSize.substring(0, this.tablediv.fontSize.length - 2);
        this.tablediv.paddingTop = parseInt(parseInt(this.height) - nn) / 2 + "px";
        // Lisa modify for change the fontsize and padding Top ---end---
      }
      this.tablediv.backgroundColor = this.color;

      if (this.shape == 'Round') {
        this.tablediv.borderRadius = "50%";
      } else {
        this.tablediv.borderRadius = "5px";
      }

      // document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
      // document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .table-remove {
    font-size: 8px;
    font-weight: bold;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 10px;
    height: 10px;
    color: red;
    background-color: #ffffff;
  }
  .table-remove2 {
    font-size: 16px;
    position: absolute;
    top: 2px;
    right: 2px;
  }
  .table-resize {
    font-size: 8px;
    font-weight: bold;
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 10px;
    height: 10px;
    color: red;
    background-color: #ffffff;
  }
</style>